import { Component, EventEmitter, Output, ViewEncapsulation, ViewChild, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private http: HttpClient,
    private route: ActivatedRoute//,
  ) { }
  displayName: any;
  roleName: any;

  user: any;
  roleList = [];
  userGroupAuthorityDtoList = [];

  ngOnInit() {
    const user = this.authenticationService.userValue;
    if (user) {
      this.displayName = user.FirstName + ' ' + user.LastName;
      this.roleName = user.RoleName;
      this.userGroupAuthorityDtoList = user["userGroupAuthorityDtoList"];
      //console.log(this.userGroupAuthorityDtoList);
    }
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  isPermission(page) {
    var returnPermission = false;
    try {
      var authrorityDto;
      if (this.userGroupAuthorityDtoList && this.userGroupAuthorityDtoList.length > 0) {
        for (var i = 0; i < this.userGroupAuthorityDtoList.length; i++) {
          authrorityDto = this.userGroupAuthorityDtoList[i];
          //console.log(authrorityDto);
          if (authrorityDto && authrorityDto["PageTypeCode"] == page && "N" != authrorityDto["AuthorityTypeCode"]) {
            returnPermission = true;
          }
        }
      }
    } catch { }
    return returnPermission;
  }

}
