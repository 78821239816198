import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authenticationService.userValue;
      if (currentUser && this.authenticationService.isUserLoggedIn()) {
        //console.log(currentUser["RoleCode"] + " " + route.data?.pageTypeCode);
        // check if route is restricted by role
        /*if (currentUser["RoleCode"]) {
          if (route.data?.pageTypeCode && route.data?.pageTypeCode.split(",").indexOf(currentUser["RoleCode"]) === -1) {
            this.router.navigate(['home']);
            return false;
          }
        }
        else if (route.data.roles && route.data.roles?.indexOf(currentUser.currentRole) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['home']);
          return false;
        }*/
        
        if (currentUser.UserName) {
          //this.router.navigate(['dashboard']);
          /*if (this.router.url == "/") {
            this.router.navigate(['dashboard']);
          }*/
          // authorised so return true
          return true;
        }
        else {
          this.router.navigate(['login-ad']);
          
          return false;        
        }
      }
      // not logged in so redirect to login page with the return url
      //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      //this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
     /* if (this.router.url != "") {
        console.log(this.router.url);
        this.router.navigate(['']);
        return false;
      }*/
      this.router.navigate(['login-ad']);
      return false;
    }
}
