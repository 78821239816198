import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ReportService {
    httpClient: any;
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl) {
    }

  ExportBudgetSummaryReport(obj): Observable<HttpEvent<Blob>> {
    return this._httpClient.request(new HttpRequest(
      'POST',
      this.baseUrl + 'api/Report/ExportBudgetSummaryReport',
      obj,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  ExportOperationReport(obj): Observable<HttpEvent<Blob>> {
    return this._httpClient.request(new HttpRequest(
      'POST',
      this.baseUrl + 'api/Report/ExportOperationReport',
      obj,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
    /*searchBudgetAB(obj) {
        return this._httpClient.post<any>(this.baseUrl + 'api/Dashboard/searchBudgetAB', obj);
    }

    searchBudgetByType(obj) {
        return this._httpClient.post<any>(this.baseUrl + 'api/Dashboard/searchBudgetByType', obj);
    }*/
}
