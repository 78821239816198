import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-supply-base-management-modal',
  templateUrl: './supply-base-management-modal.component.html',
  styleUrls: ['./supply-base-management-modal.component.css']
})
export class SupplyBaseManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  costList = [];
  allocationList = [];
  costDtoList: any[] = [];
  costTypeCodeList: any[];
  costNameList: any[] = [];
  selectedCostNameList: any[] = [];
  tcrBudgetCostTypeList: any[] = [];
  tcBudgetTypeList: any[] = [];
  allocationYearList: any[] = [];
  selectedSupplyBaseCode: any;
  addCostFlag: any = true;
  addAllocationFlag: any = true;
  isReadonly: boolean = false;
  costCountingLink = 0;
  allocationCountingLink = 0;
  masterObj: any;
  paramId: any;
  pageMode: any;
  blockCostList: any[] = [
    {
      Code: "ALL",
      BlockName: "ALL",
    },
  ];
  blockAllocationList: any[] = [];
  updateBy: any;
  updateDate: any;
  chosenYearDate: Date;
  checkAllocation: boolean;
  costDefaluteList: any[];
  defaluteFlagList: any[] = [];
  yearList: any[] = [];
  supplyBaseCostList: any[] = [];
  budgetTypeCode: any;
  countInput = 1;

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
    }];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    for (var block of this.masterObj.tcBlock) {
      this.blockCostList.push(block);
    }
    this.blockAllocationList = this.masterObj.tcBlock;
    this.costDtoList = this.masterObj.costListDto;
    this.costTypeCodeList = this.masterObj.tcCostType;
    this.tcrBudgetCostTypeList = this.masterObj.tcrBudgetCostType;
    this.tcBudgetTypeList = this.masterObj.tcBudgetType;

    //this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == "SS" && item.DefaluteFlag == true);
    
    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 11; i++) {
      if (i == 5) {
        this.yearList.push({ Code: currentYear });
      }
      else if (i < 5) {
        this.yearList.push({ Code: currentYear - (5 - i) });
      }
      else if (i > 5) {
        this.yearList.push({ Code: currentYear + (i - 5) });
      }
    }

    this.formZone = this.formBuilder.group({
      'SupplyBaseName': new FormControl({ value: this.masterObj?.resolverData?.SupplyBaseName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ActiveFlag': new FormControl({ value: this.masterObj?.resolverData?.ActiveFlag == true ? true : this.masterObj?.resolverData?.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'SupplyBaseCostList': this.formBuilder.array([]),
      'SupplyBaseAllocationList': this.formBuilder.array([]),
      'CreateDate': new FormControl({ value: "", disabled: true }),
      'CreateName': new FormControl({ value: "", disabled: true }),
      'UpdateDate': new FormControl({ value: "", disabled: true }),
      'UpdateName': new FormControl({ value: "", disabled: true }),
    });

    this.checkAllocation = this.masterObj?.resolverData?.AllocationFlag == true ? true : this.masterObj?.resolverData?.AllocationFlag == false ? false : false
    
    if (this.checkAllocation) {
      this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == "SS" && item.DefaluteFlag == true);
      var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SS"));
    } else {
      this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == "SN" && item.DefaluteFlag == true);
      var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SN"));
    }

    for (var budgetCostFilter of budgetCostFiltedList) {
      this.supplyBaseCostList.push(this.costTypeCodeList.find(x => x.Code == budgetCostFilter.CostTypeCode));
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.loadCostDefaluteFlag(this.costDefaluteList);
      this.formZone.get("ActiveFlag").setValue(true);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.SupplyBaseCostList &&
      this.masterObj?.resolverData.SupplyBaseCostList?.length > 0) {
      this.loadCost(this.masterObj?.resolverData.SupplyBaseCostList);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.SupplyBaseAllocationList &&
      this.masterObj?.resolverData.SupplyBaseAllocationList?.length > 0) {
      this.loadAllocation(this.masterObj?.resolverData.SupplyBaseAllocationList);
    }
 
  }

  loadCost(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        if (this.checkAllocation) {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SS"));
          this.costItems.push(this.formBuilder.group({
            "BlockCode": new FormControl({ value: costListObj.BlockCode == null ? "ALL" : costListObj.BlockCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required]))
          }));
        } else {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SN"));
          this.costItems.push(this.formBuilder.group({
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
          }));
        }
      }
      this.checkAddCost();
    }
  }

  loadCostDefaluteFlag(costList) {
    console.log(costList);
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];      
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        if (this.checkAllocation) {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SS"));
          this.costItems.push(this.formBuilder.group({
            "BlockCode": new FormControl({ value: costListObj.BlockCode == null ? "ALL" : costListObj.BlockCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required]))
          }));
        } else {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SN"));
          this.costItems.push(this.formBuilder.group({
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required]))
          }));
        }

      }
      this.checkAddCost();
    }
  }

  addCost() {
    if (this.checkAllocation) {
      this.costItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
      }));
    } else {
      this.costItems.push(this.formBuilder.group({
        "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
      }));
    }
    this.checkAddCost();
  }


  deleteCost(index) {
    this.costItems.removeAt(index);
    this.costNameList.splice(index, 1);
    this.defaluteFlagList.splice(index, 1);
    this.addCostFlag = true;
  }

  checkAddCost() {
   
    if (!this.checkAllocation &&
        (this.costItems.length >= this.costDtoList.filter(x => x.VariableTypeCode == 'C' && x.BudgetTypeCode == "SN").length)) {
        
        this.addCostFlag = false;
      }
    
  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      item.get("CostId").setValue(null);
       console.log(this.costDtoList)
      if (this.checkAllocation) {
        this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false && x.BudgetTypeCode == "SS");
      } else {
        this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false && x.BudgetTypeCode == "SN");
      }
      console.log(this.costNameList[idx])
      
    }
  }

  //onSearchChange(searchValue: string): void {
  //  this.supplyBaseCostList = [];
  //  if (!this.formZone.get('SupplyBaseName').invalid) {
  //    this.checkAllocation = this.formZone.get("SupplyBaseName").value.toUpperCase() == 'TAKITTA' ? true : false;
  //  } else {
  //    this.checkAllocation = false;
  //  }
  //  if (this.checkAllocation) {
  //    this.countInput = 0;
  //    this.clearFormArray(this.costItems);
  //    var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SS"));
  //  } else {
  //    if (this.countInput == 0) {
  //      this.clearFormArray(this.costItems);
  //    }
  //    var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SN"));
  //    this.countInput++;
  //  }
  //  for (var budgetCostFilter of budgetCostFiltedList) {
  //    this.supplyBaseCostList.push(this.costTypeCodeList.find(x => x.Code == budgetCostFilter.CostTypeCode));
  //  }
  //}

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  checkAddAllocation() {
    if (this.allocationList.length >= this.masterObj.tcBlock.length) {
      this.addAllocationFlag = false;
    }
  }

  loadAllocation(allocationList) {
   
    if (allocationList && allocationList.length > 0) {
      for (var i = 0; i < allocationList.length; i++) {
        var allocationObj = allocationList[i];
        this.allocationYearList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
        this.allocationItems.push(this.formBuilder.group({
          "BlockCode": new FormControl({ value: allocationObj.BlockCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "Percentage": new FormControl({ value: allocationObj.Percentage, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: allocationObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "StartYear": new FormControl({ value: parseInt(allocationObj.StartYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "EndYear": new FormControl({ value: parseInt(allocationObj.EndYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(allocationObj.UpdateDttm), disabled: true }),
          "UpdateBy": new FormControl({ value: allocationObj.UpdateName, disabled: true })
        }));

        this.startYearChangeValue(i);
        this.endYearChangeValue(i);

        this.checkAddAllocation();
      }
    }
  }

  addAllocation() {
    this.allocationYearList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
    this.allocationItems.push(this.formBuilder.group({
      "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Percentage": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      "StartYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "EndYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "UpdateDate": new FormControl({ value: "", disabled: true }),
      "UpdateBy": new FormControl({ value: "", disabled: true })
    }));

    this.checkAddAllocation();
  }

  deleteAllocation(index) {
    this.allocationItems.removeAt(index);
    this.addAllocationFlag = true;
  }

  startYearChangeValue(index) {
    var allocationItem = this.allocationItems.at(index);
    var startYear = allocationItem.get("StartYear").value;
    this.allocationYearList[index]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);
    this.formZone.updateValueAndValidity();
  }

  endYearChangeValue(index) {
    var allocationItem = this.allocationItems.at(index);
    var endYear = allocationItem.get("EndYear").value;
    this.allocationYearList[index]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);
    this.formZone.updateValueAndValidity();
  }

  get costItems(): FormArray {
    return this.formZone.get('SupplyBaseCostList') as FormArray;
  }

  get allocationItems(): FormArray {
    return this.formZone.get('SupplyBaseAllocationList') as FormArray;
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";
    if (this.checkAllocation) {
      requestObj.AllocationFlag = true;
      if (this.allocationItems.length == 0) {
        message = "The data cannot be save. Because the allocation is null.";
      }
    }

    if (!this.formZone.valid || message) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }
      return;
    }


    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    this.MasterData.checkSupplyBaseCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveSupplyBase(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/supply-base-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/supply-base-management']);
  }

}
