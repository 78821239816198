import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { param } from 'jquery';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';



@Component({
  selector: 'app-project-management-modal',
  templateUrl: './project-management-modal.component.html',
  styleUrls: ['./project-management-modal.component.css']
})
export class ProjectManagementModalComponent implements OnInit {

  @Input() formZone: FormGroup;
  dtOptions: any = {};
  /*costList = [];
  addCostFlag: any = true;*/
  isReadonly: boolean = false;
  paramId: any;
  pageMode: any;
  masterObj: any;
  blockNameList: any;
  paramCode: any;
  /*costTypeCodeList: any[];
  costTypeNameList: any;
  costDefaluteList: any[];
  costNameList: any[] = [];*/
  defaluteFlagList: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  projectList: any[] = [];
  activityList: any[] = [];
  //costDtoList: any[] = [];
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
     
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
   
   
      this.blockNameList = this.masterObj.tcBlock.filter(x => x.ActiveFlag == true);
  
    this.formZone = this.formBuilder.group({
      'Code': new FormControl({ value: this.masterObj?.resolverData?.Code, disabled: (this.masterObj?.resolverData?.Code) }, Validators.compose([Validators.required])),
      'ProjectName': new FormControl({ value: this.masterObj?.resolverData?.ProjectName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'BlockName': new FormControl({ value: this.masterObj?.resolverData?.BlockCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
     
      'Description': new FormControl({ value: this.masterObj?.resolverData?.Description, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ActiveFlag': new FormControl({ value: this.masterObj?.resolverData?.ActiveFlag == true ? true : this.masterObj?.resolverData?.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'CreateDate': new FormControl({ value: "", disabled: true }),
      'CreateName': new FormControl({ value: "", disabled: true }),
      'UpdateDate': new FormControl({ value: "", disabled: true }),
      'UpdateName': new FormControl({ value: "", disabled: true }),
    });
    console.log(this.masterObj)
    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Code != null) {
      this.paramCode = this.masterObj?.resolverData?.Code;
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.formZone.get("ActiveFlag").setValue(true);
    }
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/project-management']);
  }
  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";
    console.log(requestObj)

    if (!this.formZone.valid || message) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }
      return;
    }

    if (this.paramId && this.paramId != "0") {
      
      requestObj["CodeEncoded"] = this.paramId;
      console.log(this.paramId);
    }
    /*if (this.paramCode) {
      requestObj["CodeEncoded"] = this.paramCode;
    }*/

    this.MasterData.checkProject(requestObj).subscribe(
      result => {
        console.log(result);
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveProject(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/project-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )

  }

}
