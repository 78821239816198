import { Renderer2, OnInit, Inject, Component, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() formZone: FormGroup;
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {

    this.formZone = this.formBuilder.group({
      'username': new FormControl(null),
      'password': new FormControl(null)
    });
  }

  checkLoginEnter(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.checkLogin();
    }
  }

  checkLogin() {
    if (this.formZone.get("username").value == '' || this.formZone.get("password").value == '') {
      alert("Please input Username and Password.");
      this._document.getElementById("username").focus();
    }
    else if (this.formZone.get("username").value == null || this.formZone.get("password").value == null) {
      alert("Please input Username and Password.");
      this._document.getElementById("username").focus();
    }
    else {
      this._authenticationService.login(this.formZone.get("username").value, this.formZone.get("password").value).subscribe(res => {

        if (res) {
          if (res["errorCode"]) {
            document.getElementById("loginErrorContainer").style.display = "block";
            document.getElementById("loginError").innerHTML = this.getErrorString(res["errorCode"]);
            document.getElementById("username").focus();
          } else {
            this.router.navigate(['home']);
          }
        }

        /*if (res) {
          if (res["data"] && res["data"]["name"] == "Error") {
            //error from aws
            if (res["data"]["message"]) {
              document.getElementById("loginErrorContainer").style.display = "block";
              document.getElementById("loginError").innerHTML = res["data"]["message"];
            }
          }
          else {
            console.log(res);
            //this.router.navigate(['./dashboard', { relativeTo: this.route }]);
            this.router.navigate(['./home']);
          }
        }*/
      }, error => {
        console.error(error);
          
      })
    }
  }

  getQuerystringObject() {
    var data = {};
    var loc = window.location + "";
    var idx = loc.indexOf("?");

    if (idx != -1) {
      var locSubstring = loc.substring(idx + 1);
      var splitLoc = locSubstring.split("&");

      for (var i = 0; i < splitLoc.length; i++) {
        var chk = splitLoc[i].indexOf("=");
        if (chk > -1) {
          data[splitLoc[i].substring(0, chk)] = splitLoc[i].substring(chk + 1)
        }
      }
    }
    return data;
  }

  getErrorString(errorCode) {
    switch (errorCode) {
      case "ERR-001":
        return "Username or password is incorrect.";
      case "ERR-002":
        return "Role is disable, please contact system admin.";
      case "ERR-003":
        return "User is disable, please contact system admin.";
      default:
        return "Please login again";
    }
  }

  initPage() {
    var queryStringObj = this.getQuerystringObject();
    if (typeof queryStringObj["errorCode"] != "undefined") {
      document.getElementById("loginErrorContainer").style.display = "block";
      document.getElementById("loginError").innerHTML = this.getErrorString(queryStringObj["errorCode"]);
    }
    document.getElementById("username").focus();
  }
}
