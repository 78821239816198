import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-update-log',
  templateUrl: './update-log.component.html',
  styleUrls: ['./update-log.component.css']
})
export class UpdateLogComponent implements OnInit {

  constructor() { }

  @Input() createName: string;
  @Input() createDttm: string; 
  @Input() updateName: string;
  @Input() updateDttm: string;


  ngOnInit(): void {
  //  alert(this.createName)
  }

}
