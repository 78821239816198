import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class DashboardService {
    httpClient: any;
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl) {
    }

    searchBudgetSummaryAB(obj) {
      return this._httpClient.post<any>(this.baseUrl + 'api/Dashboard/searchBudgetSummaryAB', obj);
    }

    /*searchBudgetAB(obj) {
        return this._httpClient.post<any>(this.baseUrl + 'api/Dashboard/searchBudgetAB', obj);
    }

    searchBudgetByType(obj) {
        return this._httpClient.post<any>(this.baseUrl + 'api/Dashboard/searchBudgetByType', obj);
    }*/
}
