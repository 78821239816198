import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FreezeService {
  httpClient: any;

  constructor(private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl) {
  }

   
  updateFreeze(obj) {
      return this._httpClient.post<any>(this.baseUrl + 'api/Freeze/updateFreeze', obj);
  }

  getFreeze(obj) {
      return this._httpClient.post<any>(this.baseUrl + 'api/Freeze/getFreeze', obj);
  }
}
