import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';
import { UserService } from '../../../_services/User.service';


export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];


    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.css']
})
export class UserProfileModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  paramId: any;
  pageMode: any;
  masterObj: any;
  roleList: any;
  locationList: any;
  userDto: any;
  disablePassword: any = false;
  isEditPassword: any = false;
  editFlag: any = false;

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private userService: UserService,
    private router: Router) { }


   createName: string;
   createDttm: String;
   updateName: string;
   updateDttm: String; 

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });


    //alert(this.paramId);

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    this.locationList = this.masterObj.tcLocation;

    this.userService.searchUserGroupPaging({}).subscribe(
      data => {
        //console.log(data);
        this.roleList = data.detail.filter(item => item.Active_Flag == true );
      }
    );

    this.formZone = this.formBuilder.group({
      'UserName': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required])),
      'Password': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required])),
      'ConfirmPassword': new FormControl({ value: '', disabled: false }),
      'UserGroupId': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required])),
      'FirstName': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required])),
      'LastName': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required])),
      'Email': new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.email])),
      'PhoneNo': new FormControl({ value: '', disabled: false }),
      'LocationCode': new FormControl({ value: '', disabled: false }),
      'ActiveFlag': new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      'PttepFlag': new FormControl({ value: false, disabled: false }, Validators.compose([Validators.required]))
    }, {
        validator: MustMatch('Password', 'ConfirmPassword')
    });

    if (this.pageMode == 'edit') {
      this.editFlag = true;
    }

    //alert(this.paramId);
    if (this.paramId != 0) {
      //this.userService.viewUser()


      var a = {};
      a["IdEncoded"] = this.paramId;

      this.userService.viewUser(a).subscribe(result => {
        //console.log(result);
         // view user
        this.userDto = result;
        this.formZone = this.formBuilder.group({
          'UserName': new FormControl({ value: result?.UserName, disabled: true }, Validators.compose([Validators.required])),
          'Password': new FormControl({ value: '', disabled: this.pageMode == "view" }),
          'ConfirmPassword': new FormControl({ value: '', disabled: this.pageMode == "view" }),
          'UserGroupId': new FormControl({ value: result?.UserGroupId, disabled: this.pageMode == "view" }, Validators.compose([Validators.required])),
          'FirstName': new FormControl({ value: result?.FirstName, disabled: this.pageMode == "view" }, Validators.compose([Validators.required])),
          'LastName': new FormControl({ value: result?.LastName, disabled: this.pageMode == "view" }, Validators.compose([Validators.required])),
          'Email': new FormControl({ value: result?.Email, disabled: this.pageMode == "view" }, Validators.compose([Validators.required, Validators.email])),
          'PhoneNo': new FormControl({ value: result?.PhoneNo, disabled: this.pageMode == "view" }),
          'LocationCode': new FormControl({ value: result?.LocationCode, disabled: this.pageMode == "view" }),
          'PttepFlag': new FormControl({ value: result?.PttepFlag == true ? true : result?.PttepFlag == false ? false : null, disabled: this.pageMode == "view" }, Validators.compose([Validators.required])),
          'ActiveFlag': new FormControl({ value: result?.ActiveFlag == true ? true : result?.ActiveFlag == false ? false : null, disabled: this.pageMode == "view" }, Validators.compose([Validators.required]))
        }, {
            validator: MustMatch('Password', 'ConfirmPassword')
        });

        this.createName = result.CreateName;
        this.createDttm = CommonUtilService.formatDateTime(result.CreateDttm);
        this.updateName = result.UpdateName;
        this.updateDttm = CommonUtilService.formatDateTime(result.UpdateDttm);
        
      });

    } else {
      // empty user
      
    }

    //this.formZone.get("PttepFlag").valueChanges.subscribe(checked => {
    //  if (!checked) {
    //    this.formZone.get("Password").setValidators([Validators.required]);
    //  } else {
    //    this.formZone.controls['Password'].clearValidators();
    //    this.formZone.get("Password").setValue(null);
    //    this.formZone.get("ConfirmPassword").setValue(null);
    //  }
    //  this.formZone.get("Password").updateValueAndValidity();
    //});

    
  }

  changePttepFlag() {
    if (!this.formZone.get("PttepFlag").value) {
        this.formZone.get("Password").setValidators([Validators.required]);
      } else {
        this.formZone.controls['Password'].clearValidators();
        this.formZone.get("Password").setValue(null);
        this.formZone.get("ConfirmPassword").setValue(null);
      }
      this.formZone.get("Password").updateValueAndValidity();
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  changePassword() {
    this.isEditPassword = true;
  }

  close() {

    this.router.navigate(['/user-profile']);
  }

  isClickEditPassword() {
    this.isEditPassword = !this.isEditPassword;
    if (this.isEditPassword) {
      this.formZone.get("Password").setValidators([Validators.required]);
    } else {
      this.formZone.get("Password").setValidators(null);
    }
    this.formZone.get("Password").updateValueAndValidity();
  }

  isDuplicateUserName() {
    if (!this.formZone.get("UserName").value) {
      const alertDialog = this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'Please fill User Name.',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      this.formZone.get("UserName").markAsTouched();
      return;
    }
    this.userService.isDuplicateUserName(this.formZone.get("UserName").value).subscribe(
      result => {
        if (result == 'Y') {
          const alertDialog = this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: 'This User Name is already registered. Please choose another one.',
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const alertDialog = this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: 'This User Name is available.',
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        }
      }
    );
  }

  save() {
    this.formZone.markAllAsTouched();
    if (!this.formZone.valid) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      console.log(errors);
      return;
    }

    this.userService.isDuplicateUserName(this.formZone.get("UserName").value).subscribe(
      result => {
        if (result == 'Y' && this.pageMode=='add') {
          const alertDialog = this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: 'This User Name is already registered. Please choose another one.',
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

              var userDtoTosave = this.formZone.getRawValue();
              //if (this.formZone.get('PttepFlag').value) {
              //  userDtoTosave["Password"] = this.userDto["Password"];
              //}
              //console.log(userDto);

              //return;
              if (this.paramId && this.paramId != "0") {
                userDtoTosave["IdEncoded"] = this.paramId;
              }

              userDtoTosave["EditPassword"] = this.isEditPassword;

              this.userService.saveUser(userDtoTosave).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/user-profile']);
                    });
                },
                error => console.error(error)
              )
            }
          });


        }
      }
    );

   


  }

}
