import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flying-info-management',
  templateUrl: './flying-info-management.component.html',
  styleUrls: ['./flying-info-management.component.css']
})
export class FlyingInfoManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
