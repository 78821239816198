import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { CampaignService } from '../../_services/Campaign.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
@Component({
  selector: 'app-campaign-cost-detail',
  templateUrl: './campaign-cost-detail.component.html',
  styleUrls: ['./campaign-cost-detail.component.css']
})
export class CampaignCostDetailComponent implements OnInit {

  @Input() campaignId: string;
  @Input() monthCode: string;
  @Input() year: string;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;

  
  @Input() formCampaign: FormGroup;
  paramCampaignId: string;
  paramMonthCode: string;
  paramYear: string;
  CampaignName: string;
  campaignMonth: any;
  BlockCode: string;
  monthName: string;

  tcActivity = [];
  tcBlock = [];
  tcProject = [];
 
  campaignRigMonthList = [];

  campaignVesselMonthList = [];

  campaignSupplyBaseMonthList = [];

  campaignMarineSupportMonthList = [];

  campaignHelicopterMonthList = [];

  campaignExtraBudgetMonthList = [];


  constructor(private campaignService: CampaignService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.formCampaign = this.formBuilder.group({
      'CampaignName': new FormControl({ disabled: true }, Validators.compose([Validators.required])),
      'StartDate': new FormControl(null, Validators.compose([Validators.required])),
      'EndDate': new FormControl(null, Validators.compose([Validators.required])),
  //    'BlockCode': new FormControl("", Validators.compose([Validators.required])),
  //    'ProjectCode': new FormControl("", Validators.compose([Validators.required])),
     // 'ActivityCode': new FormControl("", Validators.compose([Validators.required])),
      'BlockName': new FormControl("", Validators.compose([Validators.required])),
      'ProjectName': new FormControl("", Validators.compose([Validators.required])),
      'ActivityName': new FormControl("", Validators.compose([Validators.required])),
      'Description': new FormControl("")
    });


  }


  ngOnChanges(changes: SimpleChanges) {
   // alert(this.paramCampaignId)
    //
    this.paramCampaignId = this.campaignId;
    this.paramMonthCode = this.monthCode;
    this.paramYear = this.year;
  //  alert(this.paramCampaignId + " " + this.paramMonthCode + " " + this.paramYear);
    this.monthName = CommonUtilService.getMonthShortNameByCode(this.monthCode);
    this.viewCampaignMonth();
   

  }


  viewCampaignMonth(){
    var request = {
      "Id": this.paramCampaignId,
      "year": this.paramYear,
      "month": this.paramMonthCode
    };

    this.campaignService.viewCampaignMonth(request).subscribe(
      result => {

        this.tcActivity = result.tcActivity;
        this.tcBlock = result.tcBlock;
        this.tcProject = result.tcProject;
        this.campaignMonth = result.resolverData;
        this.CampaignName = this.campaignMonth.CampaignName;
        this.BlockCode = this.campaignMonth.BlockCode;
        this.formCampaign.get('CampaignName').setValue(this.campaignMonth.CampaignName);



        this.campaignMonth.ActivityName =this.tcActivity.filter(x => x.Code == this.campaignMonth.ActivityCode)[0].Description;
        this.campaignMonth.BlockName=this.tcBlock.filter(x => x.Code == this.campaignMonth.BlockCode)[0].BlockName;
        this.campaignMonth.ProjectName =this.tcProject.filter(x => x.Code == this.campaignMonth.ProjectCode)[0].Description;
        
      //  this.formCampaign.get('BlockCode').setValue(this.campaignMonth.BlockCode);
        this.formCampaign.get('StartDate').setValue(this.campaignMonth.StartDate);
        this.formCampaign.get('EndDate').setValue(this.campaignMonth.EndDate);
      //  this.formCampaign.get('ActivityCode').setValue(this.campaignMonth.ActivityCode);
       // this.formCampaign.get('ProjectCode').setValue(this.campaignMonth.ProjectCode);
        this.formCampaign.get('Description').setValue(this.campaignMonth.Description);

        this.formCampaign.get('ActivityName').setValue(this.campaignMonth.ActivityName);
        this.formCampaign.get('BlockName').setValue(this.campaignMonth.BlockName);

        this.formCampaign.get('ProjectName').setValue(this.campaignMonth.ProjectName);




        this.formCampaign.get('CampaignName').disable();
        this.formCampaign.get('BlockName').disable();
        this.formCampaign.get('StartDate').disable();
        this.formCampaign.get('EndDate').disable();
        this.formCampaign.get('ActivityName').disable();
        this.formCampaign.get('ProjectName').disable();
        this.formCampaign.get('Description').disable();
       

        this.campaignRigMonthList = this.campaignMonth.campaignRigMonthList;
        this.campaignVesselMonthList = this.campaignMonth.campaignVesselMonthList;
        this.campaignSupplyBaseMonthList = this.campaignMonth.campaignSupplyBaseMonthList;
        this.campaignMarineSupportMonthList = this.campaignMonth.campaignMarineSupportMonthList;
        this.campaignHelicopterMonthList = this.campaignMonth.campaignHelicopterMonthList;
        this.campaignExtraBudgetMonthList = this.campaignMonth.campaignExtraBudgetMonthList;

      },
      error => console.error(error));
  }
   

  tabChange($event) {
    console.log($event)
  }


  viewCampaignMonthByUpdateCost(msg) {
    //cam(msg);
    console.log(msg);
    this.viewCampaignMonth();
  }

}
