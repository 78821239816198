import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { CalculateCostService } from '../../../_helpers/CalculateCostService';
import { CampaignService } from '../../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
 
@Component({
  selector: 'app-campaign-rig-modal',
  templateUrl: './campaign-rig-modal.component.html',
  styleUrls: ['./campaign-rig-modal.component.css']
})
export class CampaignRigModalComponent  implements OnInit {
  @Input() formZone: FormGroup;
  campaignRigMonth: any;
  freeze: boolean;
  abStage: boolean;
  canFreeze: boolean;
  isReadonly: boolean;
  countingList = 0;
  
  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    //   private userService: UserService,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService
   ) { }
  
  ngOnInit(): void {
    this.campaignRigMonth = this._data.resolverData;
    this.freeze = this._data.freeze;
    this.abStage = this._data.abStage;
    this.canFreeze = this._data.canFreeze;
    this.isReadonly = this._data.isReadonly;

    this.formZone = new FormGroup({
      rigName: new FormControl({ value: this.campaignRigMonth.RigName, disabled: true }),
      campaignName: new FormControl({ value: this._data.CampaignName, disabled: true }),
      month: new FormControl({ value: CommonUtilService.getMonthShortNameByCode(this.campaignRigMonth.MonthCode), disabled: true }),
      year: new FormControl({ value: this.campaignRigMonth.Year, disabled: true }),
      dayCount: new FormControl({ value: this.abStage ? this.campaignRigMonth.DayAb : this.campaignRigMonth.DayCf, disabled: (this.freeze && !this.canFreeze) || this.isReadonly }),
    });
 
    this.loadCost();
   
  }

  formatDateTime(UpdateDttm) { 
   return CommonUtilService.formatDateTime(UpdateDttm)
  }


  loadCost() {

    for (var i = 0; i < this.campaignRigMonth.campaignRigMonthCostList.length; i++) {
      var costObj = this.campaignRigMonth.campaignRigMonthCostList[i];
      this.formZone.addControl("fromValueCf_" + i, new FormControl({ value: this.abStage ? costObj.ValueAb: costObj.ValueCf, disabled: (costObj.VariableTypeCode == 'D' || (this.freeze && !this.canFreeze) || this.isReadonly) }, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();
      costObj["fromValueCf"] = "fromValueCf_" + i;
      
      this.countingList++;

    }
  }


  calculateCost() {
    const self = this;
    this.campaignRigMonth.campaignRigMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);

    });
    
    const calObj = new CalculateCostService();
    calObj._days = parseFloat(self.formZone.get("dayCount").value);
    calObj.calculateCostList(this.campaignRigMonth.campaignRigMonthCostList, this.campaignRigMonth.costParentDtoList);
    this.campaignRigMonth.campaignRigMonthCostList.forEach(function (data) {
      self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
    });
  }

  save() {
    
    const self = this;
    this.campaignRigMonth.DayCf = self.formZone.get("dayCount").value;
 
    this.campaignRigMonth.campaignRigMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);
      if (self.abStage) {
        data["ValueAb"] = data["ValueCf"];
      }

    });
    this.campaignRigMonth.AbFlag = this.abStage;


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       
        this.campaignService.updateCampaignRigMonth(this.campaignRigMonth).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });
 
  }

}
