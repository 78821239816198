import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  httpClient: any;
  constructor(private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl) {
  }

  searchUserGroupPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/searchUserGroupPaging', obj);
  }

  searchUserPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/searchUserPaging', obj);
  }

  saveUser(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/saveUser', obj);
  }

  viewUser(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/viewUser', obj);
  }
  

  saveUserGroup(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/saveUserGroup', obj);
  }

  viewUserGroup(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/viewUserGroup', obj);
  }

  deleteUserGroupById(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/deleteUserGroupById', obj);
  }

  isDuplicateUserName(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/User/isDuplicateUserName?username=' + obj);
  }

  isDuplicateUserGroupName(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/User/isDuplicateUserGroupName', obj);
  }
   
}
