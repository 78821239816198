import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-ad',
  templateUrl: './login-ad.component.html',
  styleUrls: ['./login-ad.component.css']
})
export class LoginAdComponent implements OnInit {

  constructor(private authService: MsalService,
    private router: Router,
    private _authenticationService: AuthenticationService) {
  }
  ngOnInit(): void {

    console.log(this.authService.instance.getActiveAccount())
    this.authService.instance.handleRedirectPromise().then(res => {
  
      if (res != null && res.account != null) {
    
       // console.log(res.account.username)
        var username = res.account.username;
        this.authService.instance.setActiveAccount(res.account)


        this._authenticationService.LoginPTTUser(username).subscribe(res => {

          if (res) {
            if (res["errorCode"]) {
              document.getElementById("loginErrorContainer").style.display = "block";
              document.getElementById("loginError").innerHTML = this.getErrorString(res["errorCode"]);
             // document.getElementById("username").focus();
            } else {
              this.router.navigate(['home']);
            }
          }

          /*if (res) {
            if (res["data"] && res["data"]["name"] == "Error") {
              //error from aws
              if (res["data"]["message"]) {
                document.getElementById("loginErrorContainer").style.display = "block";
                document.getElementById("loginError").innerHTML = res["data"]["message"];
              }
            }
            else {
              console.log(res);
              //this.router.navigate(['./dashboard', { relativeTo: this.route }]);
              this.router.navigate(['./home']);
            }
          }*/
        }, error => {
          console.error(error);

        })


      }
    })
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  login() {
    this.authService.loginRedirect();

  /*  this.authService.loginPopup()
      .subscribe((response: AuthenticationResult) => {

        this.authService.instance.setActiveAccount(response.account);

        console.log(response.account.username)
      });*/
  }

  logout() {
    this.authService.logout()
  }

  loginExternalUser() {

    this.router.navigate(['login']);
  }

  getErrorString(errorCode) {
    switch (errorCode) {
      case "ERR-001":
        return "You are not authorized";
      case "ERR-002":
        return "Role is disable, please contact system admin.";
      case "ERR-003":
        return "User is disable, please contact system admin.";
      default:
        return "Please login again";
    }
  }
} 
