import { Component, OnInit, AfterViewInit, Inject  } from '@angular/core';
 
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CampaignModalComponent } from './campaign-modal/campaign-modal.component';
import { ConfirmModalComponent } from '../common-modal/confirm-modal/confirm-modal.component';
import { CampaignService } from '../_services/Campaign.service';
import { AlertModalComponent } from '../common-modal/alert-modal/alert-modal.component';
import { FreezeService } from '../_services/Freeze.service';
import { ABStageService } from '../_services/ABStage.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CommonUtilService } from '../_helpers/CommonUtilService';
import { Router } from '@angular/router';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {

  constructor(public _matDialog: MatDialog,
 
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private freezeService: FreezeService,
    private abStageService: ABStageService) { }
  yearList = [];
  yearDropdownList = [];
  campaignId: string;
  monthCode: string=null;
  year = 2021;
 
  showCostType = '';
  thisYear = 2021;
  canFreeze = false;
  canReductionPercentage = false;
  color = 'primary';
  freeze = false;
  abStage = false;
  granttType = 'M';
  selectedYear = null;
  Percentage = 0;
  toggle = 'E';
  isReadonly: boolean = false;
  colNo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  colList = [{ 'text': 'Jan' },
    { 'text': 'Feb' },
    { 'text': 'Mar' },
    { 'text': 'Apr' },
    { 'text': 'May' },
    { 'text': 'Jun' },
    { 'text': 'Jul' },
    { 'text': 'Aug' },
    { 'text': 'Sep' },
    { 'text': 'Oct' },
    { 'text': 'Nov' },
    { 'text': 'Dec' }]

  colList2 = [{ 'text': 'Q1' },
  { 'text': 'Q2' },
  { 'text': 'Q3' },
  { 'text': 'Q4' }]

/*  dataList = [{ 'id': '1', 'text': 'M9', 'level': 1, 'hasChild': true, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0  },
    { 'id': '1.1', 'text': 'M9 East Appraisal - Dev Drilling (3A)', 'level': 2, 'hasChild': false, "C1": 0, "C2": 0, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '1.2', 'text': 'M9 East Appraisal - Dev Drilling (5A)', 'level': 2, 'hasChild': true, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0   },
    { 'id': '1.2.1', 'text': 'Vessel 01', 'level': 3, 'hasChild': false, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '1.2.2', 'text': 'Vessel 02', 'level': 3, 'hasChild': false, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '1.3', 'text': 'M9 East Appraisal - Dev Drilling (7A)', 'level': 2, 'hasChild': true, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '1.3.1', 'text': 'Vessel 01', 'level': 3, 'hasChild': false, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '1.3.2', 'text': 'Vessel 02', 'level': 3, 'hasChild': false, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '2', 'text': 'M3', 'level': 1, "C1": 0, 'hasChild': true, "C2": 0, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '2.1', 'text': 'test', 'level': 2, "C1": 0, 'hasChild': true, "C2": 0, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },

    { 'id': '2.1.3', 'campaign_id':'campaign_id 001', 'text': 'test level 3', 'level': 2, "C1": 0, 'hasChild': false, "C2": 0, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 1, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },

    //{ 'id': '2.1', 'text': 'M3 East Appraisal - Dev Drilling (5A)', 'level': 2, 'hasChild': true, "C1": 1, "C2": 1, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    //{ 'id': '2.1.1', 'text': 'Vessel 01', 'level': 3, 'hasChild': false, "C1": 1, "C2": 1, "C3": 1, "C4": 1, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0 },
    { 'id': '3', 'text': 'M7', 'level': 1, "C1": 0, 'hasChild': false, "C2": 0, "C3": 0, "C4": 0, "C5": 0, "C6": 0, "C7": 0, "C8": 0, "C9": 0, "C10": 0, "C11": 0, "C12": 0  }]
*/
  dataList = [];
dataListOrg = [];
  cMax = 0;
  cMin = 0;
  ngOnInit(): void {
    this.cMax = 12;
    this.cMin = 1;

    var d = new Date();
    this.year = d.getFullYear();


    var page = "C"
    const user = this.authenticationService.userValue;
    
    if (user) {
      var authorizedCode = CommonUtilService.getPageAuthorized(page, user);
      this.canFreeze = user.userGroupDto.CanFreezeFlag
      this.canReductionPercentage = user.userGroupDto.CanReductionPercentage
      
      if (!(authorizedCode == 'V' || authorizedCode == 'M')) {
        this.router.navigate(['/home']);
      }
      this.isReadonly = authorizedCode == 'V';
    } else {
      this.router.navigate(['/home']);
    }
      
    //test//
    this.dataList.forEach(function (data) {
      for (var i = 13; i < 29; ++i) {
        data['C' + i] = 0;
      }
    });

    this.dataList = this.dataList.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);

    this.dataListOrg = $.extend(true, [], this.dataList);
    //this.dataListOrg = this.dataList.slice();

  //  console.log(this.dataList)



    this.initailCampaignTableByMode(0);

  }


  initailCampaignTableByMode(selectedYear) {
    this.campaignService.getCampaignTableByMode(this.granttType, selectedYear).subscribe(
      result => {
       
        //console.log(result.selectedYear)
        this.selectedYear = result.selectedYear;
        this.Percentage = result.reductionPercentage;
        if (this.granttType == 'M') {
          this.yearDropdownList = [];
          this.year = result.selectedYear;
       
          this.thisYear = result.thisYear;
         
          var starYear = 2000;
          var endYEar = this.thisYear + 5;

          for (var i = starYear; i <= endYEar; ++i) {
            this.yearDropdownList.push({ 'Code': i });
          }

          this.yearList = [];
          for (var i = -1; i < 6; ++i) {
            this.yearList.push({ 'text': this.year + i });
          }

          result.campaignTableList.forEach(function (data) {
            for (var i = 1; i < 13; ++i) {
              var index = data["MonthYearList"].findIndex(x => x.month == i && x.year == result.selectedYear);

              data['C' + i] = index == -1 ? 0 : 1;

            }
          });
          this.dataList = result.campaignTableList;
          this.dataListOrg = $.extend(true, [], this.dataList);


        } else if (this.granttType == 'Q') {
          this.year = result.selectedYear;
          this.thisYear = result.thisYear;
          var years = [];
          for (var i = -1; i < 6; ++i) {
            years.push(this.year + i);
          }
          this.yearList = [];
          for (var i = -1; i < 6; ++i) {
            this.yearList.push({ 'text': this.year + i });
          }


          result.campaignTableList.forEach(function (data) {

            var indexC = 1;
            years.forEach(function (y) {
              for (var i = 1; i < 5; i++) {
            /*    console.log(y)
                console.log(data.text)
                console.log(data.quaterYearList)*/
                var index = data["quaterYearList"].findIndex(x => x.quater == i && x.year == y);

                data['C' + indexC] = index == -1 ? 0 : 1;
       /*         console.log(indexC)
                console.log(index)*/
                indexC++;
              }
            });


          });
          this.dataList = result.campaignTableList;
          this.dataListOrg = $.extend(true, [], this.dataList);
        }
        else if (this.granttType == 'Y') {

          this.year = result.selectedYear;
          this.thisYear = result.thisYear;

          var years = [];
          for (var i = -1; i < 6; ++i) {
            years.push(this.year + i);
          }

          this.yearList = [];
          for (var i = -1; i < 6; ++i) {
            this.yearList.push({ 'text': this.year + i });
          }
      
          result.campaignTableList.forEach(function (data) {

            var indexC = 1;
            years.forEach(function (y) {
              console.log(data["yearList"])
              var index = data["yearList"].findIndex(x => x == y);
              console.log(index)
              data['C' + indexC] = index == -1 ? 0 : 1;
              indexC++;
            });


          
          });
          this.dataList = result.campaignTableList;
          this.dataListOrg = $.extend(true, [], this.dataList);

        }
        //this.toggleBtn('C');
       // alert('sad')

        var then = this
        //setInterval(function () {
        setTimeout(function () {
          try {
            $('span[level="2"]').unbind("dragstart");

            $(document).unbind('dragenter drop');
            $('span[id^="C"]').unbind("dragend");
          } catch { }
          then.setDrop();

          then.toggleBtn('C')

        }, 500);
      }
      , error => console.error(error));
  }

  ngAfterViewInit(): void {


   
    /*$('span[id^="C"]').on("dragend", function (event) {

      //console.log("dragend:" + $(this).attr('id'))
      //console.log(event)
      if (!dragSuccess) {
        //then.dataList[0].C1 = 0;
        //then.dataList = $.extend(true, [], then.dataListOrg);
        then.dataListOrg.forEach(function (value) {
          then.dataList.forEach(function (value2) {
            if (value2.id == value.id) {
              
              for (var i = 1; i < 13; ++i) {
                value2['C' + i] = value['C' + i];
              }
              //value2 = $.extend(true, [], value);
              //value2 = value;

            }
          });
        });
      }
      dragSuccess = false;
    });*/

    
  }

  setDrop(): void {
    var then = this;

    if (this.granttType != 'M') {
      return;
      //document.getElementById("myTd")["colSpan"] = this.colList.length;
    }
    //$('.level1, .level2, .level3').on("dragstart", function (event) {
    var isDrag = false;
    var startContinue = 0;
    var dragstart = '';
    var dragSuccess = false;
    var lastreq = 0;

    //$('span[id^="C"]').on("dragstart", function (event) {
    $('span[level="2"]').on("dragstart", function (event) {
      var dt = event.originalEvent.dataTransfer;
      console.log("dragstart:" + $(this).attr('id'))
      dt.setData('Text', $(this).attr('id'));
      dragstart = $(this).attr('id');
    });

    $('span[id^="C"]').on("dragend", function (event) {

      //console.log("dragend:" + $(this).attr('id'))
      //console.log(event)
      if (!dragSuccess) {
        //then.dataList[0].C1 = 0;
        //then.dataList = $.extend(true, [], then.dataListOrg);
        /*then.dataListOrg.forEach(function (value) {
          then.dataList.forEach(function (value2) {
            if (value2.id == value.id) {

              for (var i = 1; i < then.cMax; ++i) {
                value2['C' + i] = value['C' + i];
              }
              //value2 = $.extend(true, [], value);
              //value2 = value;

            }
          });
        });*/
        const dialogRef = then.confirmDialog.open(ConfirmModalComponent, {
          disableClose: true,
          data: {
            message: 'Would you like to save?',
            buttonText: {
              ok: 'Save',
              cancel: 'No'
            }
          }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            then.dataListOrg = $.extend(true, [], then.dataList);
          } else {
            then.dataListOrg.forEach(function (value) {
              then.dataList.forEach(function (value2) {
                if (value2.id == value.id) {

                  for (var i = 1; i < then.cMax + 1; ++i) {
                    value2['C' + i] = value['C' + i];
                  }

                }
              });
            });
          }
        });
      }
      isDrag = false;
      startContinue = 0;
      dragSuccess = false;
    });


    //$('table td').on("dragenter dragover drop", function (event) {
    $(document).on('dragenter drop', 'table td', function (event) {
      //event.originalEvent['dataTransfer'].dropEffect = "move";
      event.preventDefault();

      /*if (event.type === 'dragenter') {
        var data = dragstart;
        var id = $(this).children().attr('id');
        if (id != undefined) {
          //console.log(data.split("_")[1])
          //console.log(id.split("_")[1])
          if (data == id) {
            return;
          }
          if (data.split("_")[1] != id.split("_")[1]) {
            return;
          }

          //console.log("detach:" + id)
          //console.log(this)
          //$(this).children().detach();
          //console.log(this)
          ////var de = $('#' + data).detach();
          //de.appendTo($(this));
          //console.log($('#' + 'c1_1.2').attr('id'))
          ////$(document.getElementById(data)).clone().attr('id', id).appendTo($(this));
          var start = parseInt(data.split("_")[0].replace('C', ''));
          var end = parseInt(id.split("_")[0].replace('C', ''));
          //console.log(then.dataList[0])
          then.dataListOrg.forEach(function (value) {
            then.dataList.forEach(function (value2) {
              if (value2.id == value.id) {

                for (var i = 1; i < 13; ++i) {
                  value2['C' + i] = value['C' + i];
                }
                //value2 = $.extend(true, [], value);
                //value2 = value;

              }
            });
          });
          then.dataList.forEach(function (value) {
            if (value.id == id.split("_")[1]) {

              console.log(value[data.split("_")[0]])
              if (value[data.split("_")[0]] == 1 || value[data.split("_")[0]] == 0) {
                console.log('e')
                //console.log(value[id.split("_")[0]])
                //var cValue = (value[id.split("_")[0]] + 1) % 2;
                if (value[id.split("_")[0]] == 0) {
                  value[id.split("_")[0]] = 1;
                  if (start < end) {
                    start += 1;
                    for (start; start < end; ++start) {
                      value['C' + start] = 1;
                    }
                  } else {

                    for (start; start > end; --start) {
                      value['C' + start] = 1;
                    }
                  }
                } else {
                  console.log('c')
                  if (start < end) {
                    if (start > cMin) {
                      if (value['C' + (start - 1)] == 1) {
                        console.log('a')
                        return;
                      }
                    }
                    value[data.split("_")[0]] = 0;
                    start += 1;
                    for (start; start < end; ++start) {
                      value['C' + start] = 0;
                    }
                  } else {
                    if (start < cMax) {
                      if (value['C' + (start + 1)] == 1) {
                        console.log('b')
                        return;
                      }
                    }
                    value[data.split("_")[0]] = 0;
                    for (start; start > end; --start) {
                      value['C' + start] = 0;
                    }
                  }
                }
                //console.log(value)
              }
            }
          });

        } else {
          //console.log($('#' + 'c1_1.2').attr('id'))
          //$(document.getElementById(data)).clone().attr('id', data).appendTo($(this));
        }
      }*/

      if (event.type === 'dragenter' /*|| event.type === 'dragover'*/) {
        /* jquery events frequency */
        /*if (event.type === 'dragenter') {
          var d = new Date();
          var currenttime = d.getTime(); //get the time of this change event
          var interval = currenttime - lastreq; //how many milliseconds since the last request
          lastreq = currenttime;
          if (interval < 300) { //more than 2 seconds
            //lastreq = currenttime; //set lastreq for next change event
            //perform AJAX call
            isDrag = true;
            //console.log('xxx')
            return;
          }
        }

        if (event.type === 'dragover') {
          var d = new Date();
          var currenttime = d.getTime(); //get the time of this change event
          var interval = currenttime - lastreq; 
          if (interval > 600 && isDrag) { //more than 2 seconds
            //lastreq = currenttime; //set lastreq for next change event
            //perform AJAX call
            //console.log('yyy')
            //return;
          } else {
            return;
          }
         
        }*/
        isDrag = false;

        var data = dragstart;
        var id = $(this).children().attr('id');
        if (id != undefined) {
          //console.log(data.split("_")[1])
          //console.log(id.split("_")[1])

          if (data == id) {
            if (startContinue == 0) {
              return;
            }
            //return;
          }
          if (data.split("_")[1] != id.split("_")[1]) {
            return;
          }

          //console.log("detach:" + id)
          //console.log(this)
          //$(this).children().detach();
          //console.log(this)
          ////var de = $('#' + data).detach();
          //de.appendTo($(this));
          //console.log($('#' + 'c1_1.2').attr('id'))
          ////$(document.getElementById(data)).clone().attr('id', id).appendTo($(this));
          var start = parseInt(data.split("_")[0].replace('C', ''));
          var end = parseInt(id.split("_")[0].replace('C', ''));
          var chkId = '';
          var dataC2 = data.split("_")[0];


          if (startContinue != 0) {
            if (startContinue == end) {
              return;
            }
            start = startContinue;
            dataC2 = 'C' + startContinue;
          }
          startContinue = end;
          console.log('start:' + start)
          //console.log(then.dataList[0])
          /*then.dataListOrg.forEach(function (value) {
            then.dataList.forEach(function (value2) {
              if (value2.id == value.id) {

                for (var i = 1; i < then.cMax; ++i) {
                  value2['C' + i] = value['C' + i];
                }
                //value2 = $.extend(true, [], value);
                //value2 = value;

              }
            });
          });*/
          var dataListFilter = then.dataList
            .filter(value => value.id == id.split("_")[1]);
          dataListFilter.forEach(function (value) {
          //then.dataList.forEach(function (value) {
            if (value.id == id.split("_")[1]) {
              chkId = value.id;
              console.log(value[dataC2])
              if (value[dataC2] == 1 || value[dataC2] == 0) {
                console.log('e')
                //console.log(value[id.split("_")[0]])
                //var cValue = (value[id.split("_")[0]] + 1) % 2;
                if (value[id.split("_")[0]] == 0) {
                  value[id.split("_")[0]] = 1;
                  if (start < end) {
                    start += 1;
                    for (start; start < end; ++start) {
                      value['C' + start] = 1;
                    }
                  } else {

                    for (start; start > end; --start) {
                      value['C' + start] = 1;
                    }
                  }
                } else {
                  console.log('c')
                  if (start < end) {
                    if (start > then.cMin) {
                      if (value['C' + (start - 1)] == 1) {
                        console.log('a')
                        return;
                      }
                    }
                    value[dataC2] = 0;
                    start += 1;
                    for (start; start < end; ++start) {
                      value['C' + start] = 0;
                    }
                  } else {
                    if (start < then.cMax) {
                      if (value['C' + (start + 1)] == 1) {
                        console.log('b')
                        return;
                      }
                    }
                    value[dataC2] = 0;
                    for (start; start > end; --start) {
                      value['C' + start] = 0;
                    }
                  }
                }
                //console.log(value)
              }
            }
          });

          if (chkId != null) {
            var level2Data = then.dataList
              .filter(item => item.id === chkId)[0];
            var level3Datas = then.dataList
              .filter(item => item.id.indexOf(chkId + '.') == 0);

            level3Datas.forEach(function (level3Data) {
              for (var i = 1; i < then.cMax + 1; ++i) {
                level3Data['C' + i] = level2Data['C' + i];
              }
            });

            var level1Data = then.dataList
              .filter(item => item.level == 1 && item.id == chkId.split('.')[0])[0];
            var level2Datas = then.dataList
              .filter(item => item.level == 2 && item.id.indexOf(chkId.split(/\.(?=[^\.]+$)/)[0] + '.') == 0);

            var min = 100;
            var max = 0;
            level2Datas.forEach(function (level2Data) {
              for (var i = 1; i < then.cMax + 1; ++i) {
                if (level2Data['C' + i] == 1) {
                  if (i < min) {
                    min = i;
                  }
                  if (i > max) {
                    max = i;
                  }
                }
              }
              for (var i = 1; i < then.cMax + 1; ++i) {
                if (i >= min && i <= max) {
                  level1Data['C' + i] = 1;
                } else {
                  level1Data['C' + i] = 0;
                }
              }
            });
          }

        } else {
          //console.log($('#' + 'c1_1.2').attr('id'))
          //$(document.getElementById(data)).clone().attr('id', data).appendTo($(this));
        }
      }

      if (event.type === 'dropForAlone') {
     //   alert('d')
        startContinue = 0;
        dragSuccess = true;
        var data = event.originalEvent['dataTransfer'].getData('Text', $(this).attr('id'));
        console.log("drop:" + data)
        var id = $(this).children().attr('id');
        if (id != undefined) {
          //console.log(data.split("_")[1])
          //console.log(id.split("_")[1])
          if (data == id) {
            return;
          }
          if (data.split("_")[1] != id.split("_")[1]) {
            return;
          }

          const dialogRef = then.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
           
              console.log("detach:" + id)
              //console.log(this)
              //$(this).children().detach();
              //console.log(this)
              ////var de = $('#' + data).detach();
              //de.appendTo($(this));
              //console.log($('#' + 'c1_1.2').attr('id'))
              ////$(document.getElementById(data)).clone().attr('id', id).appendTo($(this));
              var start = parseInt(data.split("_")[0].replace('C', ''));
              var end = parseInt(id.split("_")[0].replace('C', ''));
              var chkId = '';
              then.dataList.forEach(function (value) {
                if (value.id == id.split("_")[1]) {
                  if (value[data.split("_")[0]] == 1) {
                    chkId = value.id;
                    //console.log(value[id.split("_")[0]])
                    //var cValue = (value[id.split("_")[0]] + 1) % 2;
                    if (value[id.split("_")[0]] == 0) {
                      value[id.split("_")[0]] = 1;
                      if (start < end) {
                        start += 1;
                        for (start; start < end; ++start) {
                          value['C' + start] = 1;
                        }
                      } else {

                        for (start; start > end; --start) {
                          value['C' + start] = 1;
                        }
                      }
                    } else {
                      if (start < end) {
                        if (start > then.cMin) {
                          if (value['C' + (start - 1)] == 1) {
                            return;
                          }
                        }
                        value[data.split("_")[0]] = 0;
                        start += 1;
                        for (start; start < end; ++start) {
                          value['C' + start] = 0;
                        }
                      } else {
                        if (start < then.cMax) {
                          if (value['C' + (start + 1)] == 1) {
                            return;
                          }
                        }
                        value[data.split("_")[0]] = 0;
                        for (start; start > end; --start) {
                          value['C' + start] = 0;
                        }
                      }
                    }
                    //console.log(value)
                  }
                }
              });

              //calculate width of parent and child
              if (chkId != null) {
                var level2Data = then.dataList
                  .filter(item => item.id === chkId)[0];
                var level3Datas = then.dataList
                  .filter(item => item.id.indexOf(chkId + '.') == 0);

                level3Datas.forEach(function (level3Data) {
                  for (var i = 1; i < then.cMax + 1; ++i) {
                    level3Data['C' + i] = level2Data['C' + i];
                  }
                });

                var level1Data = then.dataList
                  .filter(item => item.level == 1 && item.id == chkId.split('.')[0])[0];
                var level2Datas = then.dataList
                  .filter(item => item.level == 2 && item.id.indexOf(chkId.split(/\.(?=[^\.]+$)/)[0] + '.') == 0);

                var min = 100;
                var max = 0;
                level2Datas.forEach(function (level2Data) {
                  for (var i = 1; i < then.cMax + 1; ++i) {
                    if (level2Data['C' + i] == 1) {
                      if (i < min) {
                        min = i;
                      }
                      if (i > max) {
                        max = i;
                      }
                    }
                  }
                  for (var i = 1; i < then.cMax + 1; ++i) {
                    if (i >= min && i <= max) {
                      level1Data['C' + i] = 1;
                    } else {
                      level1Data['C' + i] = 0;
                    }
                  }
                });
              }

              then.dataListOrg = $.extend(true, [], then.dataList);
              //then.dataListOrg = then.dataList.slice();
            }
          });
        } else {
          //console.log($('#' + 'c1_1.2').attr('id'))
          //$(document.getElementById(data)).clone().attr('id', data).appendTo($(this));
        }

      };

      if (event.type === 'drop') {
      //  alert('s')
        startContinue = 0;
        isDrag = false;
        dragSuccess = true;
        var data = event.originalEvent['dataTransfer'].getData('Text', $(this).attr('id'));
        console.log("drop:" + data)
        var id = $(this).children().attr('id');
        if (id != undefined) {
          //console.log(data.split("_")[1])
          //console.log(id.split("_")[1])
          var isClear = false;
          if (data == id) {
            //isClear = true;
            //return;
          }
          /*if (data.split("_")[1] != id.split("_")[1]) {
            isClear = true;
            //return;
          }*/
          if (isClear){
            then.dataListOrg.forEach(function (value) {
              then.dataList.forEach(function (value2) {
                if (value2.id == value.id) {

                  for (var i = 1; i < then.cMax + 1; ++i) {
                    value2['C' + i] = value['C' + i];
                  }
                }
              });
            });
            return;
          }

       /*   if (this.granttType != "M") {
            then.dataListOrg.forEach(function (value) {
              then.dataList.forEach(function (value2) {
                if (value2.id == value.id) {

                  for (var i = 1; i < then.cMax + 1; ++i) {
                    value2['C' + i] = value['C' + i];
                  }
                  //value2 = $.extend(true, [], value);
                  //value2 = value;

                }
              });
            });
            return;
          }*/
         
          const dialogRef = then.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              console.log("+++++++++++++++++++++++++++++++++++")
              var keyID = data.split("_")[1];
           
              var dataUpdateList = then.dataList.filter(value => value.id == keyID);
             
              var dataUpdate = dataUpdateList[0];
              var startFlag = false;
              var mStart = 0;
              var mEnd = 0;
              for (var i = 1; i <= 12; i++) {
                var mValue = dataUpdate["C" + i];
                if (mValue == 1 && !startFlag) {
                  mStart = i;
                  startFlag = true;
                }
                if (mValue == 0 && startFlag) {
                  mEnd = (i-1);
                  break;
                }
                if (mValue==1&&i == 12) {
                  mEnd = i;
                }

              }


              var startDate = new Date(then.year, (mStart - 1), 1, 7, 0, 0);
              var endDate = new Date(then.year, mEnd, 0, 7, 0, 0);
          
              console.log("year : " + then.year);
              console.log("startDate : " + startDate)
              console.log("endDate : " + endDate)
              var campaignId = keyID.split(".")[1];
              console.log("campaignId : " + campaignId)


              var requestUpdate = {
                "Id": campaignId,
                "StartDate": startDate,
                "EndDate": endDate,
              }
          
                then.campaignService.updatePeriodCampaign(requestUpdate).subscribe(
                  result => {
//console.log('test')
                    const alertDialog = then.alertDialog.open(AlertModalComponent, {
                      disableClose: true,
                      data: {
                        message: 'Save Successfully.',
                        buttonText: {
                          cancel: 'OK'
                        }
                      }
                    });
                    alertDialog.afterClosed()
                      .subscribe(response => {
                        then.dataListOrg = $.extend(true, [], then.dataList);
                      });

                
                },
              error => console.error(error))

            

           

            } else {
              console.log(then.dataListOrg)
              then.dataListOrg.forEach(function (value) {
                then.dataList.forEach(function (value2) {
                  if (value2.id == value.id) {

                    for (var i = 1; i < then.cMax + 1; ++i) {
                      value2['C' + i] = value['C' + i];
                    }
                    //value2 = $.extend(true, [], value);
                    //value2 = value;

                  }
                });
              });
            }
          });
        } else {
          //console.log($('#' + 'c1_1.2').attr('id'))
          //$(document.getElementById(data)).clone().attr('id', data).appendTo($(this));
        }

      };
    });
  }

  gtype(str): void {
    //alert(str)
    //console.log($('#c1_1.2').attr('id'))

    //var e = document.getElementById("c1_1.2");
    //e.innerHTML = 'Found you';
    if (this.granttType == 'M') {
      //document.getElementById("myTd")["colSpan"] = this.colList.length;
      this.colNo = [];
      for (var i = 1; i < 13; ++i) {
        this.colNo.push(i);
      }
      this.cMax = 12;

      var then = this;
      setTimeout(function () {
        $('span[level="2"]').unbind("dragstart");
        //$('table td').unbind("dragenter dragover drop");
        $(document).unbind('dragenter drop');
        $('span[id^="C"]').unbind("dragend");
        then.setDrop();
      }, 300);
    }
    if (this.granttType == 'Q') {
      this.colNo = [];
      for (var i = 1; i < 29; ++i) {
        this.colNo.push(i);
      }
      this.cMax = 28;

      var then = this;
      setTimeout(function () {
        $('span[level="2"]').unbind("dragstart");
        //$('table td').unbind("dragenter dragover drop");
        $(document).unbind('dragenter drop');
        $('span[id^="C"]').unbind("dragend");
        then.setDrop();

      }, 300);
    }
    if (this.granttType == 'Y') {
      this.colNo = [];
      for (var i = 1; i < 8; ++i) {
        this.colNo.push(i);
      }
      this.cMax = 7;

      var then = this;
      setTimeout(function () {
        $('span[level="2"]').unbind("dragstart");
        //$('table td').unbind("dragenter dragover drop");
        $(document).unbind('dragenter drop');
        $('span[id^="C"]').unbind("dragend");
        then.setDrop();
      }, 300);
    }
  
    this.initailCampaignTableByMode(this.selectedYear == null ? 0 : this.selectedYear)

  }

  toggleH1(id): void {
    /*var lst = document.getElementsByName(str);
    //console.log($('tr[name^="h1_1"]'))
    var display = $('tr[name^="' + str+'"]').css("display");
    if (display != "none") {
      $('tr[name^="' + str +'"]').attr("style", "display:none");
      document.getElementsByName(str2 + '_plus')[0].style.display = '';
      document.getElementsByName(str2 + '_minus')[0].style.display = 'none';
    } else {
      $('tr[name^="' + str +'"]').css('display', '')
      document.getElementsByName(str2 + '_plus')[0].style.display = 'none';
      document.getElementsByName(str2 + '_minus')[0].style.display = '';
    }*/
    /*for (var i = 0; i < lst.length; ++i) {
      if (lst[i].style.display == 'none') {
        lst[i].style.display = '';
        document.getElementsByName('i1_plus')[0].style.display = 'none';
        document.getElementsByName('i1_minus')[0].style.display = '';
      } else {
        lst[i].style.display = 'none';
        document.getElementsByName('i1_plus')[0].style.display = '';
        document.getElementsByName('i1_minus')[0].style.display = 'none';
      }
    }*/

    var str = 'h' + id + '.';
    var plus = 'iPlus' + id;
    var minus = 'iMinus' + id;
    var display = $('tr[id^="' + str + '"]').css("display");

    /*$('span[id^="' + plus + '"]').each(function () {

      console.log($(this))

    })*/
    /*if (display != "none") {
      $('tr[id^="' + str + '"]').attr("style", "display:none");

      $('span[id^="' + plus + '"]').attr("style", "");
      $('span[id^="' + minus + '"]').attr("style", "display:none");
    } else {
      $('tr[id^="' + str + '"]').css('display', '')

      $('span[id^="' + plus + '"]').attr("style", "display:none");
      $('span[id^="' + minus + '"]').attr("style", "");
    }*/

    if (display != "none") {
      $('tr[id^="' + str + '"]').attr("style", "display:none");

      $('span[id="' + plus + '"]').attr("style", "");
      $('span[id="' + minus + '"]').attr("style", "display:none");
    } else {
      $('tr[id^="' + str + '"]').css('display', '')

      $('span[id="' + plus + '"]').attr("style", "display:none");
      $('span[id="' + minus + '"]').attr("style", "");

      $('span[id^="' + plus + '."]').each(function () {
        //console.log($(this).attr('id'))
        var display2 = $(this).css("display");
        if (display2 != "none") {
          var str2 = 'h' + $(this).attr('id').replace("iPlus", "") + '.';
          $('tr[id^="' + str2 + '"]').attr("style", "display:none");
        }
      })
    }
  }

  toggleBtn(mode): void {
    //console.log(this.dataList)
    this.dataList.forEach(function (value) {
      if (value.level == 1) {
        var id = value.id;
        var str = 'h' + id + '.';
        var plus = 'iPlus' + id;
        var minus = 'iMinus' + id;

        if (mode == "C") {
          $('tr[id^="' + str + '"]').attr("style", "display:none");

          $('span[id^="' + plus + '"]').attr("style", "");
          $('span[id^="' + minus + '"]').attr("style", "display:none");
        } else {
          $('tr[id^="' + str + '"]').css('display', '')

          $('span[id^="' + plus + '"]').attr("style", "display:none");
          $('span[id^="' + minus + '"]').attr("style", "");
        }
      }
    });
  }

  addCampaign() {
    this.campaignService.getCampaignObject("0").subscribe(
      result => {
      //  console.log(result)
        const self = this;
        const alertDeleteDialog = this._matDialog.open(CampaignModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            isReadonly: this.isReadonly,
            resolve: result,
            pageType: 'add',
            action: 'add'
          }
        });

        alertDeleteDialog.afterClosed()
          .subscribe(response => {
            if (response != 'Close') {
              //   self.isSearch();
              //   self.cdr.detectChanges();
              
            }

            self.initailCampaignTableByMode(self.selectedYear == null ? 0 : self.selectedYear);
          });
      },
      error => console.error(error));
    //Campaign
   
  }


  editCampaign(item) {
  
    this.campaignService.viewCampaign(item.CampaignId).subscribe(
      result => {
         console.log(result)
        const self = this;
        const alertDeleteDialog = this._matDialog.open(CampaignModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            isReadonly: this.isReadonly,
            resolve: result,
            pageType: 'add',
            action: 'edit'
          }
        });

        alertDeleteDialog.afterClosed()
          .subscribe(response => {
            if (response != 'Close') {
              //   self.isSearch();
              //   self.cdr.detectChanges();

            }

            self.initailCampaignTableByMode(self.selectedYear == null ? 0 : self.selectedYear);
          });
      },
      error => console.error(error)); 
    //Campaign

  }

  openCampaignCost(monthCode, item) {
    this.showCostType = '';
    if (this.granttType == 'M' && item.CampaignId != null
      && item['C' + monthCode] == 1) {

      var request = {};
      request["MonthCode"] = parseInt(monthCode);
      request["Year"] = this.year;
      this.freezeService.getFreeze(request).subscribe(
        result => {
          this.freeze = result.resolverData.FreezeFlag;
          this.showCostType = 'CP';
          this.monthCode = monthCode;
          this.campaignId = item.CampaignId;
        },
        error => console.error(error)); 



      this.abStageService.getABStage(request).subscribe(
        result => {
          this.abStage = result.resolverData.AbFlag;
  
        },
        error => console.error(error)); 

    
    } else {
      this.monthCode = null;
    }
  
  }


  openBlockCost(monthCode,  item) {
 
    this.showCostType = '';
    if (this.granttType == 'M' && item.level == 1
      && item['C' + monthCode] == 1) {
      var request = {};
      request["MonthCode"] = parseInt(monthCode);
      request["Year"] = this.year;
   //   alert(this.year)
      this.freezeService.getFreeze(request).subscribe(
        result => {
       this.freeze = result.resolverData.FreezeFlag;
          this.showCostType = 'BC';
          this.monthCode = monthCode;
        },
        error => console.error(error)); 

      this.abStageService.getABStage(request).subscribe(
        result => {
          this.abStage = result.resolverData.AbFlag;

        },
        error => console.error(error)); 

     
    } else {
      this.monthCode = null;
    }
 
  }

  setFreeze() {
   
    var request = {};
    request["MonthCode"] = parseInt(this.monthCode);
    request["Year"] = this.year;
    request["FreezeFlag"] = this.freeze;
    this.freezeService.updateFreeze(request).subscribe(
      result => {
     
      },
      error => console.error(error)); 

  }


  setABStage() {

    var request = {};
    request["MonthCode"] = parseInt(this.monthCode);
    request["Year"] = this.year;
    request["AbFlag"] = this.abStage;
    this.abStageService.updateABStage(request).subscribe(
      result => {

      },
      error => console.error(error));

  }

  txtCampaignTootip(monthCode, item) {
    if (this.granttType == 'M' && item.CampaignId != null) {
      return "Campaign "+item.text;
    }
    return "";
  }

  ChangeSelectedYear() {
 
    this.initailCampaignTableByMode(this.selectedYear)
  }


  updateReductionPercentage() {

    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to Reduction Percentage?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const self = this;
        let requestObj: any = {};
        requestObj.Year = this.selectedYear;
        requestObj.Percentage = this.Percentage;
        this.campaignService.updateReductionPercentage(requestObj).subscribe(
          result => {

            console.log(result)
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
              //  this.matDialogRef.close('Close')
                self.initailCampaignTableByMode(self.selectedYear == null ? 0 : self.selectedYear);
              });
          },
          error => {
            console.log(error);
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully!',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
             //   this.matDialogRef.close('Close')
              });
          }
        )
      }
    });
  }
}
