import { AbstractControl, FormGroup, FormControl, ValidationErrors, FormArray } from "@angular/forms";

export class  ErrorMessageService {
  constructor() {}
  public static getErrorMessageByCode(dateString: any): String {
      if (dateString) {
        if (dateString && dateString.hasError('required')) {
          return 'This field is required.';
        } else if (dateString.errors?.pattern) {
            return 'format of Email address is invalid.';
        }
      } else {
        return null;
      }
  }

  public static getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
    if (form instanceof FormArray) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      for (var i = 0; i < form.length; i++) {
        var formObj = form.at(i);
        Object.keys(formObj["controls"]).forEach(key => {
          // Recursive call of the FormGroup fields
          const error = this.getFormErrors(formObj.get(key));
          if (error !== null) {
            // Only add error if not null
            formErrors[key] = error;
          }
        });
      }
      
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  public static getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}
