import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-block-cost-marine-support',
  templateUrl: './block-cost-marine-support.component.html',
  styleUrls: ['./block-cost-marine-support.component.css']
})
export class BlockCostMarineSupportComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() blockMarineSupportMonthList: any[];
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() abStage: boolean;

  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
  }

  summary() {
    var result = 0;
    const self = this;
    this.blockMarineSupportMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }
    })
    return result;
  }


  summaryReduction() {
    const self = this;
    var result = 0;
    this.blockMarineSupportMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  summaryActual() {
    var result = 0;
    const self = this;
    this.blockMarineSupportMonthList.forEach(function (data) {
      if (self.formZone.get(data["fromActualAb"]).value != null) {
        result += parseFloat(self.formZone.get(data["fromActualAb"]).value);
      } else {
        result += 0;
      }
    });

    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();
  }

  loadCost() {
    if (this.blockMarineSupportMonthList.length > 0) {
      for (var i = 0; i < this.blockMarineSupportMonthList.length; i++) {
        // let costObj: any;

        var costObj = this.blockMarineSupportMonthList[i];
        //   alert(costObj['ActualAb'])
        // this.formZone.addControl("fromActualAb_" + i, new FormControl(costObj['ActualAb']));
        this.formZone.addControl("fromActualAb_" + i, new FormControl({ value: costObj.ActualAb, disabled: (this.freeze && !this.canFreeze) || this.isReadonly }));
        this.formZone.updateValueAndValidity();
        costObj['fromActualAb'] = "fromActualAb_" + i;

      }
    }
  }

  save() {

    const self = this;

    this.blockMarineSupportMonthList.forEach(function (data) {

      data["ActualAb"] = parseFloat(self.formZone.get(data["fromActualAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateBlockMarineSupportActual(this.blockMarineSupportMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('MarineSupport Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }
}
