import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignVesselModalComponent } from './campaign-vessel-modal/campaign-vessel-modal.component';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-campaign-vessel',
  templateUrl: './campaign-vessel.component.html',
  styleUrls: ['./campaign-vessel.component.css']
})
export class CampaignVesselComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() campaignVesselMonthList: any[];
  @Input() monthCode: string;
  @Input() CampaignName: string;
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
 
    this.formZone = new FormGroup({});
    this.loadCost();
  }

  loadCost() {
    if (this.campaignVesselMonthList.length > 0) {
      for (var i = 0; i < this.campaignVesselMonthList.length; i++) {
        // let costObj: any;

        var costObj = this.campaignVesselMonthList[i];
        //   alert(costObj['ActualMgoCostAb'])
     //   this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl(costObj['ActualMgoCostAb']));
     //   this.formZone.addControl("fromActualVesselCostAb_" + i, new FormControl(costObj['ActualVesselCostAb']));

       this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl({ value: costObj.ActualMgoCostAb, disabled: this.freeze }));
       this.formZone.addControl("fromActualVesselCostAb_" + i, new FormControl({ value: costObj.ActualVesselCostAb, disabled: this.freeze }));

        this.formZone.updateValueAndValidity();
        costObj['fromActualMgoCostAb'] = "fromActualMgoCostAb_" + i;
        costObj['fromActualVesselCostAb'] = "fromActualVesselCostAb_" + i;
      }
    }
  }


  summaryMgoCost() {
    var result = 0;
    const self = this;
    this.campaignVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["MgoCostAb"];
      } else {
        result += data["MgoCostCf"];
      }
     
    })
    return result;
  }

  summaryVesselCost() {
    var result = 0;
    const self = this;
    this.campaignVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["VesselCostAb"];
      } else {
        result += data["VesselCostCf"];
      }
     
    })
    return result;
  }

  summaryReductionVesselCost() {
    const self = this;

    var result = 0;
    this.campaignVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }

    })
    return result;
  }

  summaryReductionMgoCost() {
    const self = this;

    var result = 0;
    this.campaignVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageMgoAb"];
      } else {
        result += data["ReductionPercentageMgoCf"];
      }

    })
    return result;
  }

  summaryMgoActual() {
    var result = 0;
    const self = this;

    this.campaignVesselMonthList.forEach(function (data) {

      result += parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });

    return result;
  }

  summaryVesselActual() {
    var result = 0;
    const self = this;
    this.campaignVesselMonthList.forEach(function (data) {

      result += parseFloat(self.formZone.get(data["fromActualVesselCostAb"]).value);
    });

    return result;
  }





  openUpdateCost(id) {

    this.campaignService.viewVesselMonth(id).subscribe(
      result => {

      

    const alertDialog = this._matDialog.open(CampaignVesselModalComponent, {
      //panelClass: 'select-reviewer-modal',
      disableClose: true,
      maxWidth: undefined,
      height: '1200Px',
      width: '1000px',
      data: {
      
        CampaignName: this.CampaignName,
        resolverData: result.resolverData,
        freeze: this.freeze,
        abStage: this.abStage,
        canFreeze: this.canFreeze,
        isReadonly: this.isReadonly
      }
    });

    alertDialog.afterClosed()
      .subscribe(response => {
        if (response != 'Close') {

          this.childEvent.emit('vessel update');
          
        }
      });

      });
  }

  save() {

    const self = this;

    this.campaignVesselMonthList.forEach(function (data) {
      
      data["ActualVesselCostAb"] = parseFloat(self.formZone.get(data["fromActualVesselCostAb"]).value);
      data["ActualMgoCostAb"] = parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateVesselActual(this.campaignVesselMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('Vessel Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }
  //
}
