import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: '[a2pCurrency]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CurrencyInput },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInput),
      multi: true,
    }
  ]
})
export class CurrencyInput {
  // tslint:disable-next-line:variable-name
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>,
  ) {
    //console.log('created directive');
  }


  get value(): string | null {
    return (this._value == "") ? null : this._value;
  }
  @Input()
  decimal: number;

  @Input('value')
  set value(value: string | null) {  
    this._value = value;
    this.formatValue(value);
    
  }

  private formatValue(value: string | null) {
    if (value !== null) {
      this.elementRef.nativeElement.value = this.numberWithCommas(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    this._value = value.replace(/[^\d.-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private roundup(num, dec) {
    dec = dec || 0;
    var s = String(num);
    if (num % 1) s = s.replace(/5$/, '6');
    return Number((+s).toFixed(dec));
  }

  private numberWithCommas(inputValue) {
    if (!inputValue) {      
      return (inputValue == 0) ? inputValue: null;
    }
    if (inputValue && inputValue.length > 1) {
      //clearing left side zeros
      while (inputValue.charAt(0) == '0' && inputValue.length > 1 && inputValue.charAt(1) != '.') {
        inputValue = inputValue.substr(1);
      }
    }


    var minusFlag = false;
 
    if (inputValue && inputValue?.toString().startsWith("-")) {
      minusFlag = true;
    } 
    
    inputValue = inputValue?.toString().replace(/[^\d.\',']/g, '');
 
    var point = inputValue.indexOf(".");
    if (point >= 0) {
      var decimal = 3;
      if (this.decimal) {
        decimal = this.decimal + 1;
      }
      //inputValue = inputValue.slice(0, point + decimal);
      //inputValue = parseFloat(inputValue).toFixed(decimal-1).toString();
      if (inputValue.slice(-1) != '.' && inputValue.slice(point + 1).length > decimal-1) {
        inputValue = this.roundup(parseFloat(inputValue), decimal - 1).toString();
      }
    }

    var decimalSplit = inputValue.split(".");
    var intPart = decimalSplit[0];
    var decPart = decimalSplit[1];

    intPart = intPart.replace(/[^\d]/g, '');
    if (intPart.length > 3) {
      var intDiv = Math.floor(intPart.length / 3);
      while (intDiv > 0) {
        var lastComma = intPart.indexOf(",");
        if (lastComma < 0) {
          lastComma = intPart.length;
        }

        if (lastComma - 3 > 0) {
          intPart = intPart.slice(0, lastComma - 3) + "," + intPart.slice(lastComma - 3);
        }
        intDiv--;
      }
    }

    if (decPart === undefined) {
      decPart = "";
    }
    else {
      decPart = "." + decPart;
    }
    var res = intPart + decPart;
    if (minusFlag) {
      res = "-" + res;
    }
 

    return res;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(/[^\d.-]/g, '');
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyDown(event:any) {
    //var nativeValue = this.elementRef.nativeElement.value
    //if (nativeValue && (nativeValue.split(".")[1])?.length > 2) {
    //  event.preventDefault();
    //}
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.formatValue(this._value);
  }

  @HostListener('change') onchange() {
    this.formatValue(this._value);
  }

  @HostListener('focusout', ['$event.target'])
  focusout(input) {
    if (this._value !== null) {
    /*  console.log('old : ' + this._value)
      this._value = (this._value + "")?.replace(/(\.0*|(?<=(\..*))0*)$/, '');
      console.log('new : ' + this._value)
      this.elementRef.nativeElement.value = this.elementRef.nativeElement.value?.replace(/(\.0*|(?<=(\..*))0*)$/, '');*/
    }
  }

  _onChange(value: any): void {
   
  }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {
  }

}
