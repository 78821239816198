import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { CampaignService } from '../../_services/Campaign.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';

@Component({
  selector: 'app-block-cost',
  templateUrl: './block-cost.component.html',
  styleUrls: ['./block-cost.component.css']
})
export class BlockCostComponent implements OnInit {
  @Input() year: string;
  @Input() monthCode: string;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  
  showBlockFlag: boolean=false;
  paramYear: string;
  paramMonthCode: string;
  monthName: string;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;

  blockMgtcMonthList = [];
  blockHelicopterMonthList = [];
  blockTakittaMonthList = [];
  blockExtraBudgetMonthList = [];
  blockRigMonthList = [];
  blockVesselMonthList = []
  blockPsbRsbMonthList = []
  blockMarineSupportMonthList = []

  constructor(private campaignService: CampaignService) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    // alert(this.paramCampaignId)
    //alert(this.paramMonthCode)
    this.paramYear = this.year;
    this.paramMonthCode = this.monthCode;
    this.monthName= CommonUtilService.getMonthShortNameByCode(this.monthCode);
   
    this.viewBlockMonth();
     
  }
  viewBlockMonth() {
    var request = {
      "year": this.paramYear,
      "month": this.paramMonthCode
    };

    this.campaignService.viewBlockMonth(request).subscribe(
      result => {

        // console.log(result)

        this.blockMgtcMonthList = result.resolverData.blockMgtcMonthList;
        this.blockHelicopterMonthList = result.resolverData.blockHelicopterMonthList;
        this.blockTakittaMonthList = result.resolverData.blockTakittaMonthList;
        this.blockExtraBudgetMonthList = result.resolverData.blockExtraBudgetMonthList;
        this.blockRigMonthList = result.resolverData.blockRigMonthList;
        this.blockVesselMonthList = result.resolverData.blockVesselMonthList;
        this.blockPsbRsbMonthList = result.resolverData.blockPsbRsbMonthList;
        this.blockMarineSupportMonthList = result.resolverData.blockMarineSupportMonthList;

        if (this.blockMgtcMonthList ||
          this.blockHelicopterMonthList ||
          this.blockTakittaMonthList ||
          this.blockExtraBudgetMonthList ||
          this.blockRigMonthList ||
          this.blockVesselMonthList ||
          this.blockPsbRsbMonthList ||
          this.blockMarineSupportMonthList 
          ) {
          this.showBlockFlag = true;
        } else {
          this.showBlockFlag = false;
        }

      },
      error => console.error(error));
  }
  //parameter

  tabChange($event) {
    console.log($event)
  }

  viewBlockMonthByUpdateCost(msg) {
    console.log(msg);
    this.viewBlockMonth();
  }

}
