import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent{
  title = 'app';

  //currentUser: User;
  isAccessDenied = false;
  //isAccessDenied = (new String(window.location).indexOf("login") > -1);
  autosize: boolean = false;

  doSomethingToChangeSidenavWidth() {
    // do something...
    // ...then
    this.autosize = true;
    setTimeout(() => this.autosize = false, 1);
  }

  //isAccessDenied = true;
}
