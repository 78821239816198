import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignSupplyBaseModalComponent } from './campaign-supply-base-modal/campaign-supply-base-modal.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';
@Component({
  selector: 'app-campaign-supply-base',
  templateUrl: './campaign-supply-base.component.html',
  styleUrls: ['./campaign-supply-base.component.css']
})
export class CampaignSupplyBaseComponent implements OnInit {


  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }
  @Input() CampaignName: string;
  @Input() formZone: FormGroup;
  @Input() campaignSupplyBaseMonthList: any[];
  @Output() childEvent = new EventEmitter();
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  ngOnInit(): void {
  }



  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();
  }


  loadCost() {
    if (this.campaignSupplyBaseMonthList.length > 0) {
      for (var i = 0; i < this.campaignSupplyBaseMonthList.length; i++) {
        // let costObj: any;

        var costObj = this.campaignSupplyBaseMonthList[i];
        //   alert(costObj['ActualMgoCostAb'])
      //  this.formZone.addControl("fromActualAb_" + i, new FormControl(costObj['ActualAb']));
        this.formZone.addControl("fromActualAb_" + i, new FormControl({ value: costObj.ActualAb, disabled: this.freeze }));

        this.formZone.updateValueAndValidity();
        costObj['fromActualAb'] = "fromActualAb_" + i;

      }
    }
  }

  summary() {
    var result = 0;
    const self = this;
    this.campaignSupplyBaseMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }
      
    })
    return result;
  }

  summaryActual() {
    var result = 0;
    const self = this;
    this.campaignSupplyBaseMonthList.forEach(function (data) {

      result += parseFloat(self.formZone.get(data["fromActualAb"]).value);
    });

    return result;
  }

  summaryReduction() {
    const self = this;
    var result = 0;
    this.campaignSupplyBaseMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  save() {

    const self = this;

    this.campaignSupplyBaseMonthList.forEach(function (data) {

      data["ActualAb"] = parseFloat(self.formZone.get(data["fromActualAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateSupplyBaseActual(this.campaignSupplyBaseMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('SupplyBase Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

  openUpdateCost(id) {

    this.campaignService.viewSupplyBaseMonth(id).subscribe(
      result => {

        //  console.log(result)

        const alertDialog = this._matDialog.open(CampaignSupplyBaseModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            CampaignName: this.CampaignName,
            resolverData: result.resolverData,
            freeze: this.freeze,
            abStage: this.abStage,
            pageType: 'add',
            action: 'edit',
            canFreeze: this.canFreeze,
            isReadonly: this.isReadonly
          }
        });

        alertDialog.afterClosed()
          .subscribe(response => {

            if (response != 'Close') {
              //   self.isSearch();
              //   self.cdr.detectChanges();

              this.childEvent.emit('SupplyBase update');
            }
          });

      }
      , error => console.error(error));




  }

}
