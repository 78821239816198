import { Component, OnInit, Inject, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { UserService } from '../../../_services/User.service';
@Component({
  selector: 'app-user-group-modal',
  templateUrl: './user-group-modal.component.html',
  styleUrls: ['./user-group-modal.component.css']
})
export class UserGroupModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  paramId: any;
  pageMode: any;
 
  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router) { }


  userGroupDto: any = {};
  userGroupAuthorityDtoList: any = [];
  createName: string;
  createDttm: String;
  updateName: string;
  updateDttm: String; 
  
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.formZone = this.formBuilder.group({
      'UserGroupName': new FormControl("", Validators.compose([Validators.required])),
      'Description': new FormControl("", Validators.compose([Validators.required])),
      'CanFreezeFlag': new FormControl(true),
      'CanReductionPercentage': new FormControl(false),
      
      'ActiveFlag': new FormControl(true)
    });

    var a = {};
    if (this.paramId != 0) {
      a["IdEncoded"] = this.paramId;
    }

    this.userService.viewUserGroup(a).subscribe(result => {
      console.log(result);
      this.userGroupDto = result;
      this.formZone.get("UserGroupName").setValue(result["UserGroupName"]);
      this.formZone.get("Description").setValue(result["Description"]);
      this.formZone.get("ActiveFlag").setValue(result["ActiveFlag"]);
      this.formZone.get("CanFreezeFlag").setValue(result["CanFreezeFlag"]);
      this.formZone.get("CanReductionPercentage").setValue(result["CanReductionPercentage"]);
      this.userGroupAuthorityDtoList = result.userGroupAuthorityDtoList;

      this.createName = result.CreateName;
      this.createDttm = CommonUtilService.formatDateTime(result.CreateDttm);
      this.updateName = result.UpdateName;
      this.updateDttm = CommonUtilService.formatDateTime(result.UpdateDttm);
    });

  }
  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  close() {

    this.router.navigate(['/user-group']);
  }


  save() {
    this.formZone.markAllAsTouched();
    if (!this.formZone.valid) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      console.log(errors);
      return;
    }
    this.userGroupDto.UserGroupName = this.formZone.get("UserGroupName").value;
    this.userGroupDto.Description = this.formZone.get("Description").value;
    this.userGroupDto.ActiveFlag = this.formZone.get("ActiveFlag").value;
    this.userGroupDto.CanFreezeFlag = this.formZone.get("CanFreezeFlag").value;
    this.userGroupDto.CanReductionPercentage = this.formZone.get("CanReductionPercentage").value;
    
    this.userGroupDto.userGroupAuthorityDtoList = this.userGroupAuthorityDtoList;

    this.userService.isDuplicateUserGroupName(this.userGroupDto).subscribe(
      result => {
        if (result == 'Y') {
          const alertDialog = this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: 'This User Group Name is already registered. Please choose another one.',
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              //console.log(this.userGroupDto);
              this.userService.saveUserGroup(this.userGroupDto).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/user-group']);
                    });
                },
                error => console.error(error)
              );

            }
          });
        }
      }
    );
  }

}
