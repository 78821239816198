import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MasterDataService {
    httpClient: any;
  constructor(private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl ) {
  }

  // Rig Manement
  searchRigPaging(obj) {
    console.log(obj);
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchRigPaging', obj);
  }

  saveRig(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveRig', obj);
  }

  checkRigCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkRigCost', obj);
  }

  // Vessel Management
  searchVesselPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchVesselPaging', obj);
  }

  saveVessel(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveVessel', obj);
  }

  checkVesselCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkVesselCost', obj);
  }

  // Helicopter Management
  searchHelicopterPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchHelicopterPaging', obj);
  }

  saveHelicopter(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveHelicopter', obj);
  }

  checkHelicopterCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkHelicopterCost', obj);
  }

  // Marine Support Management
  searchMarineSupportPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchMarineSupportPaging', obj);
  }

  saveMarineSupport(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveMarineSupport', obj);
  }

  checkMarineSupportCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkMarineSupportCost', obj);
  }

  // Supply Base Management
  searchSupplyBasePaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchSupplyBasePaging', obj);
  }

  saveSupplyBase(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveSupplyBase', obj);
  }

  checkSupplyBaseCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkSupplyBaseCost', obj);
  }

  // Extra Budget Management
  searchExtraBudgetPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchExtraBudgetPaging', obj);
  }

  saveExtraBudget(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveExtraBudget', obj);
  }

  checkExtraBudgetCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkExtraBudgetCost', obj);
  }

  // SDB Resolver Data
  getRigMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getRigMasterObject');
  }
  getProjectMasterObject() {
    
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getProjectMasterObject');
  }

  getRigDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getRigDataObject?id=' + obj);
  }

  getVesselMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getVesselMasterObject');
  }

  getUserMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getUserMasterObject');
  }

  getVesselDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getVesselDataObject?id=' + obj);
  }

  getHelicopterDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getHelicopterDataObject?id=' + obj);
  }

  getMarineSupportrMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getMarineSupportrMasterObject');
  }

  getMarineSupportDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getMarineSupportDataObject?id=' + obj);
  }

  getSupplyBaseDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getSupplyBaseDataObject?id=' + obj);
  }

  getExtraBudgetMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getExtraBudgetMasterObject');
  }

  getExtraBudgetDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getExtraBudgetDataObject?id=' + obj);
  }

  getRigTypeDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getRigTypeDataObject?code=' + obj);
  }
  getActivityDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getActivityDataObject?code=' + obj);
  }
  getSupportTypeDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getSupportTypeDataObject?code=' + obj);
  }
  getParameterDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getParameterDataObject?code=' + obj);
  }
  getDepartmentDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getDepartmentDataObject?code=' + obj);
  }
  
  searchRigTypePaging(obj) {

    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchRigTypePaging', obj);
  }
  saveRigType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveRigType', obj);
  }
  checkRigType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkRigType', obj);
  }



  //Activity Naja Do it!!!
  searchActivityPaging(obj) {    
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchActivityPaging', obj) 
  }
  saveActivity(obj) {
    console.log(obj);
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveActivity',  obj)
  }
  checkActivity(obj) {
    console.log(obj);
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkActivity', obj);
  }
  // Block Management
  searchBlockPaging(obj) {
    //alert("test");
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchBlockPaging', obj);
  }
  getBlockDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getBlockDataObject?code=' + obj);
  }

  checkBlock(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkBlock', obj);
  }

  saveBlock(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveBlock', obj);
  }


  getVesselTypeDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getVesselTypeDataObject?code=' + obj);
  }
  // Vessel Type Manement


  
  // Vessel Type Manement

  searchParameterPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchParameterPaging', obj);
  }

  searchVesselTypePaging(obj) {
    //alert("test");
   
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchVesselTypePaging', obj);
  }

  saveParameter(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveParameter', obj);
  }

  checkParameter(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkParameter', obj);
  }

  // Department Manement
  searchDepartmentPaging(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchDepartmentPaging', obj);
  }

  getDepartmentMasterObject() {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getDepartmentMasterObject');
  }

  saveDepartment(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveDepartment', obj);
  }

  checkDepartment(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkDepartment', obj);
  }

  // Support Type Management Naja
  searchSupportTypePaging(obj) {
    console.log("HELOOOO")
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchSupportTypePaging', obj);
  }
 
  checkSupportType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkSupportType', obj);
  }
  saveSupportType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveSupportType', obj);
  }
  //VesselType ManageMent
  saveVesselType(obj) {
    
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveVesselType', obj);
  }
  checkVesselType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkVesselType', obj);
  }
  
//Project ManageMent
  searchProjectPaging(obj) {
    //console.log(obj);
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchProjectPaging', obj);
  }

  
  getProjectDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getProjectDataObject?code=' + obj);
  }
  checkProject(obj) {
   // console.log(obj)
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkProject', obj);
  }
  saveProject(obj) {
    
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveProject', obj);
  }
    

  // cost Type Manement
  searchCostTypePaging(obj) {
    console.log(obj)
    
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/searchCostTypePaging', obj);
  }

  saveCostType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/saveCostType', obj);
  }

  checkCostType(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/MasterData/checkCostType', obj);
  }

  getCostTypeDataObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/MasterData/getCostTypeDataObject?code=' + obj);
  }








  ExportBudgetSummaryReport(obj): Observable<HttpEvent<Blob>> {
    return this._httpClient.request(new HttpRequest(
      'POST',
      this.baseUrl + 'api/MasterData/ExportBudgetSummaryReport',
      obj,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
}
