import { Component, OnInit, Inject, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ErrorMessageService } from '../../_services/ErrorMessageService';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { CampaignService } from '../../_services/Campaign.service';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.css']
})
export class CampaignModalComponent implements OnInit {
  

  tcActivity = [];
  tcBlock = [];
  tcProject = [];
  rigList = [];
  vesselList = [];
  campaignRigList = [];
  campaignVesselList = [];
  campaignHelicopterList = [];
  campaignSupplyBaseList = [];
  helicopterList = [];
  campaignExtraBudgetList=[];
  checkVessleFlag: boolean = false;
  isEdit: boolean = false;
  campaignId: any;
  BlockBudgetId: any;
  isReadonly: boolean = false;
  get rigItems(): FormArray {
    return this.formCampaign.get('campaignRigList') as FormArray;
  }

  get vesselItems(): FormArray {
    return this.formCampaign.get('campaignVesselList') as FormArray;
  }

  get extraBudgetItems(): FormArray {
    return this.formCampaign.get('campaignExtraBudgetList') as FormArray;
  }

  get supplyBaseItems(): FormArray {
    return this.formCampaign.get('campaignSupplyBaseList') as FormArray;
  }

  get helicopterItems(): FormArray {
    return this.formCampaign.get('campaignHelicopterList') as FormArray;
  }


  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private cdr: ChangeDetectorRef ) { }
  @Input() formCampaign: FormGroup;
 
  ngOnInit(): void {
  //  console.log(this._data.resolve)
     this.tcActivity = this._data.resolve.tcActivity;
    this.tcBlock = this._data.resolve.tcBlock;
    this.tcProject = this._data.resolve.tcProject;
  
    this.rigList = this._data.resolve.resolverData.campaignRigList;
    this.vesselList = this._data.resolve.resolverData.campaignVesselList;
     
    this.helicopterList = this._data.resolve.resolverData.campaignHelicopterList;
    this.campaignSupplyBaseList = this._data.resolve.resolverData.campaignSupplyBaseList;
    this.campaignExtraBudgetList = this._data.resolve.resolverData.campaignExtraBudgetList;

    console.log(this.helicopterList);
    // for share function
    this.campaignRigList = this.rigList;
    this.campaignVesselList = this.vesselList;
    this.campaignHelicopterList = this.helicopterList;
    this.isReadonly = this._data.isReadonly;
    //alert(this.isReadonly)
   
    if (this._data.action == 'edit') {
      this.isEdit = true;
     
      var campaign = this._data.resolve.resolverData;
      this.campaignId = campaign.Id;
      this.BlockBudgetId = campaign.BlockBudgetId;
      var ActivityName = this.tcActivity.filter(x => x.Code == campaign.ActivityCode)[0].Description;
      var BlockName = this.tcBlock.filter(x => x.Code == campaign.BlockCode)[0].BlockName;
      var ProjectName = this.tcProject.filter(x => x.Code == campaign.ProjectCode)[0].Description;


      this.formCampaign = this.formBuilder.group({
        'CampaignName': new FormControl({ value: campaign.CampaignName,disabled: true }, Validators.compose([Validators.required])),
 /*       'StartDate': new FormControl(campaign.StartDate, Validators.compose([Validators.required])),
        'EndDate': new FormControl(campaign.EndDate, Validators.compose([Validators.required])),*/
        'StartDate': new FormControl({ value: campaign.StartDate, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'EndDate': new FormControl({ value: campaign.EndDate, disabled: this.isReadonly }, Validators.compose([Validators.required])),

        'BlockCode': new FormControl({ value: campaign.BlockCode, disabled: true }, Validators.compose([Validators.required])),
        'ProjectCode': new FormControl({ value: campaign.ProjectCode, disabled: true }, Validators.compose([Validators.required])),
        'ActivityCode': new FormControl({ value: campaign.ActivityCode, disabled: true }, Validators.compose([Validators.required])),
        'Description': new FormControl({ value: campaign.Description, disabled: true }),
        'ActivityName': new FormControl({ value: ActivityName, disabled: true }, Validators.compose([Validators.required])),
        'BlockName': new FormControl({ value: BlockName, disabled: true }, Validators.compose([Validators.required])),
        'ProjectName': new FormControl({ value: ProjectName, disabled: true }, Validators.compose([Validators.required])),
        'campaignRigList': this.formBuilder.array([]),
        'campaignVesselList': this.formBuilder.array([]),
        'campaignHelicopterList': this.formBuilder.array([]),
        'campaignSupplyBaseList': this.formBuilder.array([]),
        'campaignExtraBudgetList': this.formBuilder.array([])



      });
      this.changeBlock();
  //    this.changeActivityOrProject();
    } else { 
      this.tcActivity = this.tcActivity.filter(x => x.ActiveFlag == true);
      this.tcBlock = this.tcBlock.filter(x => x.ActiveFlag == true);
      this.tcProject = this.tcProject.filter(x => x.ActiveFlag == true);
    this.formCampaign = this.formBuilder.group({
      'CampaignName': new FormControl("", Validators.compose([Validators.required])),
      'StartDate': new FormControl(null, Validators.compose([Validators.required])),
      'EndDate': new FormControl(null, Validators.compose([Validators.required])),
      'BlockCode': new FormControl("", Validators.compose([Validators.required])),
      'ProjectCode': new FormControl("", Validators.compose([Validators.required])),
      'ActivityCode': new FormControl("", Validators.compose([Validators.required])),
      'Description': new FormControl(""),
      'campaignRigList': this.formBuilder.array([]),
      'campaignVesselList': this.formBuilder.array([]),
      'campaignHelicopterList': this.formBuilder.array([]),
      'campaignSupplyBaseList': this.formBuilder.array([]),
      'campaignExtraBudgetList': this.formBuilder.array([])

    });
     
    }
       // for share function
   
    this.loadRig();
    this.loadVessel();
    this.loadHelicopter();
       // end for share function
    this.loadSupplyBase();
    this.loadExtraBudget();
  
  }

  loadRig() {

    while (this.rigItems.length !== 0) {
      this.rigItems.removeAt(0)
    }

    if (this.campaignRigList && this.campaignRigList.length > 0) {
      for (var i = 0; i < this.campaignRigList.length; i++) {
        var rigObj = this.campaignRigList[i];
 
        this.rigItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: rigObj.Id, disabled: false }),
          "RigId": new FormControl({ value: rigObj.RigId, disabled: false }),
          "RigName": new FormControl({ value: rigObj.RigName, disabled: false }),
          "useFlag": new FormControl({ value: rigObj.useFlag, disabled: this.isReadonly })
          
        }));
      }
  
    }
    
  }

  loadVessel() {

    while (this.vesselItems.length !== 0) {
      this.vesselItems.removeAt(0)
    }

    if (this.campaignVesselList && this.campaignVesselList.length > 0) {
      for (var i = 0; i < this.campaignVesselList.length; i++) {
        var VesselObj = this.campaignVesselList[i];

        this.vesselItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: VesselObj.Id, disabled: false }),
          "VesselId": new FormControl({ value: VesselObj.VesselId, disabled: false }),
          "VesselName": new FormControl({ value: VesselObj.VesselName, disabled: false }),
          "useFlag": new FormControl({ value: VesselObj.useFlag, disabled: this.isReadonly })

        }));
      }

    }

  }

  loadHelicopter() {

    while (this.helicopterItems.length !== 0) {
      this.helicopterItems.removeAt(0)
    }
    console.log(this.campaignHelicopterList);
    if (this.campaignHelicopterList && this.campaignHelicopterList.length > 0) {
      for (var i = 0; i < this.campaignHelicopterList.length; i++) {
        var HelicopterObj = this.campaignHelicopterList[i];

        this.helicopterItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: HelicopterObj.Id, disabled: false }),
          "HelicopterId": new FormControl({ value: HelicopterObj.HelicopterId, disabled: false }),
          "HelicopterName": new FormControl({ value: HelicopterObj.HelicopterName, disabled: false }),
          "useFlag": new FormControl({ value: HelicopterObj.useFlag, disabled: this.isReadonly })

        }));
      }

    }

  }


  loadSupplyBase() {

    while (this.supplyBaseItems.length !== 0) {
      this.supplyBaseItems.removeAt(0)
    }

    if (this.campaignSupplyBaseList && this.campaignSupplyBaseList.length > 0) {
      for (var i = 0; i < this.campaignSupplyBaseList.length; i++) {
        var supplyBaseObj = this.campaignSupplyBaseList[i];

        this.supplyBaseItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: supplyBaseObj.Id, disabled: false }),
          "SupplyBaseId": new FormControl({ value: supplyBaseObj.SupplyBaseId, disabled: false }),
          "SupplyBaseName": new FormControl({ value: supplyBaseObj.SupplyBaseName, disabled: false }),
          "useFlag": new FormControl({ value: supplyBaseObj.useFlag, disabled: this.isReadonly }),
          "VesselTrip": new FormControl({ value: supplyBaseObj.VesselTrip, disabled: (!supplyBaseObj.useFlag || this.isReadonly) })
          
        }));
       // alert(supplyBaseObj.VesselTrip)
      }

    }

  }


  loadExtraBudget() {

    while (this.extraBudgetItems.length !== 0) {
      this.extraBudgetItems.removeAt(0)
    }

    if (this.campaignExtraBudgetList && this.campaignExtraBudgetList.length > 0) {
      for (var i = 0; i < this.campaignExtraBudgetList.length; i++) {
        var extraBudgetObj = this.campaignExtraBudgetList[i];

        this.extraBudgetItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: extraBudgetObj.Id, disabled: false }),
          "ExtraBudgetId": new FormControl({ value: extraBudgetObj.ExtraBudgetId, disabled: false }),
          "ExtraBudgetName": new FormControl({ value: extraBudgetObj.ExtraBudgetName, disabled: false }),
          "useFlag": new FormControl({ value: extraBudgetObj.useFlag, disabled: this.isReadonly }),
          "VesselTripOffshore": new FormControl({ value: extraBudgetObj.VesselTripOffshore, disabled: (!extraBudgetObj.useFlag || this.isReadonly) }),
          "VesselTripTka": new FormControl({ value: extraBudgetObj.VesselTripTka, disabled: (!extraBudgetObj.useFlag || this.isReadonly) })

        }));
      }

    }

  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  save() {
   this.formCampaign.markAllAsTouched();
    if (!this.formCampaign.valid) {
      var errors = ErrorMessageService.getFormErrors(this.formCampaign);
      console.log(errors);
      return;
    }
    
    let requestObj:any= {};
 
   /*   requestObj = {
      "CampaignName": this.formCampaign.get("CampaignName").value,
      "StartDate": this.formCampaign.get("StartDate").value,
      "EndDate": this.formCampaign.get("EndDate").value,
      "BlockCode": this.formCampaign.get("BlockCode").value,
      "ProjectCode": this.formCampaign.get("ProjectCode").value,
      "ActivityCode": this.formCampaign.get("ActivityCode").value,
      "Description": this.formCampaign.get("Description").value,
      "campaignRigList": this.campaignRigList.filter(x => x.useFlag),
      "campaignVesselList": this.campaignVesselList.filter(x => x.useFlag),
      "campaignHelicopterList": this.campaignHelicopterList.filter(x => x.useFlag),
      "campaignSupplyBaseList": this.checkVessleFlag?this.campaignSupplyBaseList.filter(x => x.useFlag):null,
      "campaignExtraBudgetList": this.checkVessleFlag ?this.campaignExtraBudgetList.filter(x => x.useFlag):null
    }
   */
    requestObj = this.formCampaign.getRawValue();
 
    requestObj.campaignRigList = requestObj.campaignRigList.filter(x => x.useFlag);
    requestObj.campaignHelicopterList = requestObj.campaignHelicopterList.filter(x => x.useFlag);
    requestObj.campaignVesselList = requestObj.campaignVesselList.filter(x => x.useFlag);
    
    
    requestObj.campaignSupplyBaseList = requestObj.campaignSupplyBaseList.filter(x => x.useFlag);
    requestObj.campaignExtraBudgetList = requestObj.campaignExtraBudgetList.filter(x => x.useFlag);

    if (requestObj.campaignVesselList == null || requestObj.campaignVesselList.length == 0) {
      requestObj.campaignSupplyBaseList = null;
      requestObj.campaignExtraBudgetList = null;
    }
    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.isEdit) {
          requestObj.Id = this.campaignId;
          requestObj.BlockBudgetId = this.BlockBudgetId;
          this.campaignService.updateCampaign(requestObj).subscribe(
            result => {

              console.log(result)
              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: 'Save Successfully.',
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
              alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });
            },
            error => {
              console.log(error);
              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: 'Save Successfully!',
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
              alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });
            }
          )
        } else { 
        this.campaignService.addCampaign(requestObj).subscribe(
          result => {

            if (result.status!='ERROR') {
              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: 'Save Successfully.',
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
              alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });
            } else {
              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: result.ret,
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
            /*  alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });*/

            }
          
          },
          error => {
            console.log(error);
           // console.error(error);

            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully!',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('Close')
              });
          }
        )
      }
      }
    });

  

  }

  checkSupplyBase($event) {
     
      this.supplyBaseItems.controls.forEach(x => {
        if (x.get('useFlag').value == true) {

          x.get('VesselTrip').setValidators(Validators.compose([Validators.required]));
          x.get('VesselTrip').enable();

          x.get('VesselTrip').updateValueAndValidity();
        } else {
          x.get('VesselTrip').setValidators(null);
          x.get('VesselTrip').disable();

          x.get('VesselTrip').updateValueAndValidity();
        }
      }
      );
  
  }

  checkExtraBudget($event) {
   
      this.extraBudgetItems.controls.forEach(x => {
        if (x.get('useFlag').value == true && x.get('ExtraBudgetName').value == 'Domestic') {

          x.get('VesselTripOffshore').setValidators(Validators.compose([Validators.required]));
          x.get('VesselTripOffshore').enable();

          x.get('VesselTripOffshore').updateValueAndValidity();

          x.get('VesselTripTka').setValidators(Validators.compose([Validators.required]));
          x.get('VesselTripTka').enable();

          x.get('VesselTripTka').updateValueAndValidity();
        } else {

          x.get('VesselTripOffshore').setValidators(null);
          x.get('VesselTripOffshore').disable();

          x.get('VesselTripOffshore').updateValueAndValidity();

          x.get('VesselTripTka').setValidators(null);
          x.get('VesselTripTka').disable();

          x.get('VesselTripTka').updateValueAndValidity();
        }
      }
      );
   
  }


  checkAll(ev, type) {
    
    if (type == 'RIG') {
      
      this.rigItems.controls.forEach(x => x.get('useFlag').setValue(ev.checked));
    } else if (type == 'VESSEL') {
      this.checkVessleFlag = ev.checked;
      this.vesselItems.controls.forEach(x => x.get('useFlag').setValue(ev.checked));
    } else if (type == 'HELICOPTER') {
      this.helicopterItems.controls.forEach(x => x.get('useFlag').setValue(ev.checked));
    } else if (type == 'SUPPLY_BASE') {
      this.supplyBaseItems.controls.forEach(x => x.get('useFlag').setValue(ev.checked));
       
    } else if (type == 'EXTRA_BUDGET') {
      this.extraBudgetItems.controls.forEach(x => x.get('useFlag').setValue(ev.checked));
    }

    

  }

  isAllChecked(type) {
    if (type == 'RIG') {
      
      return this.rigItems.controls.every(x => x.get('useFlag').value) && this.rigItems.controls.length>0;
    } else if (type == 'VESSEL') {

      if (this.vesselItems.controls.length > 0 && this.vesselItems.controls.filter(x => x.get('useFlag').value).length > 0) {
        this.checkVessleFlag = true;
      } else {
        this.checkVessleFlag = false;
      }
      return this.vesselItems.controls.every(x => x.get('useFlag').value) && this.vesselItems.controls.length > 0;
 
    } else if (type == 'HELICOPTER') {
      return this.helicopterItems.controls.every(x => x.get('useFlag').value) && this.helicopterItems.controls.length > 0;

    } else if (type == 'SUPPLY_BASE') {
      return this.supplyBaseItems.controls.every(x => x.get('useFlag').value) && this.supplyBaseItems.controls.length > 0;
    } else if (type == 'EXTRA_BUDGET') {
      return this.extraBudgetItems.controls.every(x => x.get('useFlag').value) && this.extraBudgetItems.controls.length > 0;
  }

    
    
    
  }

  changeBlock() {
 /*   var ProjectCode = this.formCampaign.get("ProjectCode").value;
    var ActivityCode = this.formCampaign.get("ActivityCode").value;
    var BlockCode = this.formCampaign.get("BlockCode").value;
    if (this.helicopterList != null && this.helicopterList.length>0&&
      ActivityCode != null && ActivityCode != "" &&
      ProjectCode != null && ProjectCode != "" &&
      BlockCode != null && BlockCode != "") {
      this.campaignHelicopterList = this.helicopterList.filter(item => item.BlockCode == BlockCode && item.ActivityCode == ActivityCode && item.ProjectCode == ProjectCode);
      this.loadHelicopter();
    }*/


    var ProjectCode = this.formCampaign.get("ProjectCode").value;
    var ActivityCode = this.formCampaign.get("ActivityCode").value;
    var BlockCode = this.formCampaign.get("BlockCode").value;
  /*  if (this.helicopterList != null && this.helicopterList.length > 0 &&
 
      BlockCode != null && BlockCode != "") {
      this.campaignHelicopterList = this.helicopterList.filter(item => item.BlockCode == BlockCode);
      this.loadHelicopter();
    }*/
  }


  changeActivityOrProject() {

    var ProjectCode = this.formCampaign.get("ProjectCode").value;

    var ActivityCode = this.formCampaign.get("ActivityCode").value;
    var BlockCode = this.formCampaign.get("BlockCode").value;
    if (ActivityCode != null && ActivityCode != "" &&
      ProjectCode != null && ProjectCode != "") {
      

      this.campaignRigList = this.rigList.filter(item => item.ActivityCode == ActivityCode && item.ProjectCode == ProjectCode );
      this.campaignVesselList = this.vesselList.filter(item => item.ActivityCode == ActivityCode && item.ProjectCode == ProjectCode);
      this.loadRig();
      this.loadVessel();
  /*    if (this.helicopterList != null && this.helicopterList.length > 0 && BlockCode != null && BlockCode != "") {
        this.campaignHelicopterList = this.helicopterList.filter(item => item.BlockCode == BlockCode && item.ActivityCode == ActivityCode && item.ProjectCode == ProjectCode);
        this.loadHelicopter();
      }*/

    }

 
  }

  MinEndDate: any;
  MaxStartDate: any;
  startDateChangeValue() {
 
   this.MinEndDate = this.formCampaign.get("StartDate").value;
    this.cdr.detectChanges();
  }

  endDateChangeValue() {
   
   this.MaxStartDate = this.formCampaign.get("EndDate").value;
    this.cdr.detectChanges();
  }

  delete() {

    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to delete campaign?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.isEdit) {
          
          this.campaignService.deleteCampaign(this.campaignId).subscribe(
            result => {

              console.log(result)
              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: 'Delete Successfully.',
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
              alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });
            },
            error => {
              console.log(error);
              console.error(error);


              const alertDialog = this.alertDialog.open(AlertModalComponent, {
                disableClose: true,
                data: {
                  message: 'Delete Successfully.',
                  buttonText: {
                    cancel: 'OK'
                  }
                }
              });
              alertDialog.afterClosed()
                .subscribe(response => {
                  this.matDialogRef.close('Close')
                });


            }
          )
        }  
      }
    });
  }

}
