import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { ReportService } from '../../_services/Report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../_services/ErrorMessageService';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

export class BudgetSummaryReportCriteria {
  block: any;
  view1: any;
  yearFrom1: any;
  yearTo1: any;
  view2: any;
  yearFrom2: any;
  yearTo2: any;
  view3: any;
  yearFrom3: any;
  yearTo3: any;
  abFlag: any;
  aFlag: any;
  cfFlag: any;
  apType: any;
}

@Component({
  selector: 'app-budget-summary-report',
  templateUrl: './budget-summary-report.component.html',
  styleUrls: ['./budget-summary-report.component.css']
})



export class BudgetSummaryReportComponent implements OnInit {
  @Input() formZone1: FormGroup;
  @Input() formZone2: FormGroup;
  @Input() formZone3: FormGroup;
  masterObj: any;
  blockList: any;

  criteriaSearch: BudgetSummaryReportCriteria = new BudgetSummaryReportCriteria();
  yearList = [];
  viewList = [{ 'Code': 'M', 'Description': 'Months' },
    { 'Code': 'Q', 'Description': 'Quaters' },
    { 'Code': 'Y', 'Description': 'Years' }];
  apList = [{ 'Code': 'N', 'Description': 'AB' },
 
    { 'Code': 'R', 'Description': 'Revised AB' }
 
  ];

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private alertDialog: MatDialog  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    this.blockList = this.masterObj.tcBlock;

    var d = new Date();
    var year = d.getFullYear();

    for (var i = -5; i < 6; ++i) {
      this.yearList.push({ 'Code': year + i });
    }
    this.criteriaSearch.abFlag = true;
    this.criteriaSearch.aFlag = true;
    this.criteriaSearch.cfFlag = true;
    //this.criteriaSearch.apType = 'N';

    this.formZone1 = this.formBuilder.group({
      'view1': new FormControl("", Validators.compose([Validators.required])),
      'yearFrom1': new FormControl("", Validators.compose([Validators.required])),
      'yearTo1': new FormControl("", Validators.compose([Validators.required]))
      /*'StartDate': new FormControl(null, Validators.compose([Validators.required])),
      'EndDate': new FormControl(null, Validators.compose([Validators.required])),
      'BlockCode': new FormControl("", Validators.compose([Validators.required])),
      'ProjectCode': new FormControl("", Validators.compose([Validators.required])),
      'ActivityCode': new FormControl("", Validators.compose([Validators.required])),
      'Description': new FormControl("")*/
    });

    this.formZone2 = this.formBuilder.group({
      'view2': new FormControl(""),
      'yearFrom2': new FormControl(""),
      'yearTo2': new FormControl("")
    });

    this.formZone3 = this.formBuilder.group({
      'yearFrom3': new FormControl(""),
      'yearTo3': new FormControl("")
    });
  }

  updateCriteria(zone, name): void {
    this.criteriaSearch[name] = this[zone].get(name).value;
  }

  changeYearTo(yearTo): void {
    if (yearTo == 1) {
      this.formZone2.controls['yearFrom2'].setValue(this.criteriaSearch.yearTo1 + 1);
      this.criteriaSearch.yearFrom2 = this.criteriaSearch.yearTo1 + 1;
    }
    if (yearTo == 2) {
      this.formZone3.controls['yearFrom3'].setValue(this.criteriaSearch.yearTo2 + 1);
      this.criteriaSearch.yearFrom3 = this.criteriaSearch.yearTo2 + 1;
    }
  }

  isClear(): void {
    this.criteriaSearch = new BudgetSummaryReportCriteria();

    this.criteriaSearch.abFlag = true;
    this.criteriaSearch.aFlag = true;
    this.criteriaSearch.cfFlag = true;
    //this.criteriaSearch.apType = 'N';

    this.formZone1 = this.formBuilder.group({
      'view1': new FormControl("", Validators.compose([Validators.required])),
      'yearFrom1': new FormControl("", Validators.compose([Validators.required])),
      'yearTo1': new FormControl("", Validators.compose([Validators.required]))
    });

    this.formZone2 = this.formBuilder.group({
      'view2': new FormControl(""),
      'yearFrom2': new FormControl(""),
      'yearTo2': new FormControl("")
    });

    this.formZone3 = this.formBuilder.group({
      'yearFrom3': new FormControl(""),
      'yearTo3': new FormControl("")
    });
  }

  validateZone(zone) {
    this.criteriaSearch.view2 = this.formZone2.get("view2").value;
    if (zone == 2 || zone == 3) {
      if (this.criteriaSearch.view2 || this.criteriaSearch.view3) {
        this.formZone2.controls['view2'].setValidators([Validators.required]);
        this.formZone2.controls['yearFrom2'].setValidators([Validators.required]);
        this.formZone2.controls['yearTo2'].setValidators([Validators.required]);

        this.formZone2.controls["view2"].updateValueAndValidity();
        this.formZone2.controls["yearFrom2"].updateValueAndValidity();
        this.formZone2.controls["yearTo2"].updateValueAndValidity();

        
      } 
    }
    if (zone == 3) {
      if (this.criteriaSearch.view3) {
        this.formZone3.controls['yearFrom3'].setValidators([Validators.required]);
        this.formZone3.controls['yearTo3'].setValidators([Validators.required]);

        this.formZone3.controls["yearFrom3"].updateValueAndValidity();
        this.formZone3.controls["yearTo3"].updateValueAndValidity();


      }
    }
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  export(): void {
    this.formZone1.markAllAsTouched();
    this.formZone2.markAllAsTouched();
    this.formZone3.markAllAsTouched();
    if (!this.formZone1.valid || !this.formZone2.valid || !this.formZone3.valid) {
      var errors = ErrorMessageService.getFormErrors(this.formZone1);
      console.log("zone1:" + errors);
      errors = ErrorMessageService.getFormErrors(this.formZone2);
      console.log("zone2:" + errors);
      errors = ErrorMessageService.getFormErrors(this.formZone3);
      console.log("zone3:" + errors);
      return;
    }

    //window.open('assets/report/PTTEPI_SCD_Master_Reference_Core_Test.xlsx', '_blank');
   /* var criteria = {
      "block": this.criteriaSearch.block,
    }*/

    this.criteriaSearch.view1 = this.formZone1.get("view1").value;
    this.criteriaSearch.yearFrom1 = this.formZone1.get("yearFrom1").value;
    this.criteriaSearch.yearTo1 = this.formZone1.get("yearTo1").value;

    this.criteriaSearch.view2 = this.formZone2.get("view2").value;
    this.criteriaSearch.yearFrom2 = this.formZone2.get("yearFrom2").value;
    this.criteriaSearch.yearTo2 = this.formZone2.get("yearTo2").value;

    this.criteriaSearch.yearFrom3 = this.formZone3.get("yearFrom3").value;
    this.criteriaSearch.yearTo3 = this.formZone3.get("yearTo3").value;

    var criteria = this.criteriaSearch;
    //window.open('MasterData/ExportRefCoreTest', '_blank');

    this.reportService.ExportBudgetSummaryReport(criteria)
      .subscribe(
        (data) => {
          //saveAs(data, `Test_1.csv`); // from file-saver library
          //console.log(data);
          switch (data.type) {
            case HttpEventType.DownloadProgress:
              //this.downloadStatus.emit({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100) });
              break;
            case HttpEventType.Response:
              //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
              //console.log(data);

              var contentDisposition = data.headers.get('content-disposition') || '';
              var matches = /filename=([^;]+)/ig.exec(contentDisposition);
              var fileName = (matches[1] || 'untitled').trim();

              if (navigator.userAgent.indexOf('MSIE') !== -1
                || navigator.appVersion.indexOf('Trident/') > -1) {
                /* Microsoft Internet Explorer detected in. */

                var xhr = new XMLHttpRequest();
                xhr.onloadstart = function () {
                  xhr.responseType = 'blob';
                };
                xhr.onload = function () {
                  navigator.msSaveOrOpenBlob(xhr.response, fileName);
                };
                const downloadedFile = new Blob([data.body], { type: data.body.type });
                xhr.open("GET", URL.createObjectURL(downloadedFile), true);
                xhr.send();

              } else {
                const downloadedFile = new Blob([data.body], { type: data.body.type });
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = fileName;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
                break;
              }
          }
        },
        (err: any) => {
          //console.log(`Unable to save file ${JSON.stringify(err)}`)
          //console.log(err);
          const alertDialog = this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: 'Data not found.',
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        }
      );

  }

  filterYearForm(yearForm) {
    if (yearForm) {
      var dataListFilter = this.yearList
        .filter(value => value.Code == yearForm + 1);
      //console.log(dataListFilter)
      return dataListFilter;
    }
    return [];

    //var dataListFilter = then.dataList
    //  .filter(value => value.id == id.split("_")[1]);
  }

  filterYearTo(yearForm) {
    if (yearForm) {
      var dataListFilter = this.yearList
        .filter(value => value.Code >= yearForm);
      //console.log(dataListFilter)
      return dataListFilter;
    }
    return [];

    //var dataListFilter = then.dataList
    //  .filter(value => value.id == id.split("_")[1]);
  }



}
