import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignExtraBudgetModalComponent } from './campaign-extra-budget-modal/campaign-extra-budget-modal.component';
import { CampaignService } from '../../_services/Campaign.service';
@Component({
  selector: 'app-campaign-extra-budget',
  templateUrl: './campaign-extra-budget.component.html',
  styleUrls: ['./campaign-extra-budget.component.css']
})
export class CampaignExtraBudgetComponent implements OnInit {

  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private campaignService: CampaignService) { }

  @Input() campaignExtraBudgetMonthList: [];
  @Input() monthCode: string;
  @Output() childEvent = new EventEmitter();
  @Input() CampaignName: string;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  ngOnInit(): void {
  }



  ngOnChanges(changes: SimpleChanges) {
    // alert(this.monthCode)
    //alert(this.parameter)

  }


  summary() {
    var result = 0;
    const self = this;
    this.campaignExtraBudgetMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }
    })
    return result;
  }

  summaryReduction() {
    const self = this;
    var result = 0;
    this.campaignExtraBudgetMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  openUpdateCost(id) {

    this.campaignService.viewExtraBudgetMonth(id).subscribe(
      result => {
    const alertDialog = this._matDialog.open(CampaignExtraBudgetModalComponent, {
      //panelClass: 'select-reviewer-modal',
      disableClose: true,
      maxWidth: undefined,
      height: '1200Px',
      width: '1000px',
      data: {
        CampaignName: this.CampaignName,
        resolverData: result.resolverData,
        freeze: this.freeze,
        abStage: this.abStage,
        canFreeze: this.canFreeze,
        isReadonly: this.isReadonly
      }
    });

    alertDialog.afterClosed()
      .subscribe(response => {
        if (response != 'Close') {
          this.childEvent.emit('Extrabudget update');
        }
      });
      }
      , error => console.error(error));

  }


}
