import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockCostMgtcModalComponent } from './block-cost-mgtc-modal/block-cost-mgtc-modal.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-block-cost-mgtc',
  templateUrl: './block-cost-mgtc.component.html',
  styleUrls: ['./block-cost-mgtc.component.css']
})
export class BlockCostMgtcComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() blockMgtcMonthList: any [];
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() abStage: boolean;

  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();

  }

  loadCost() {
    if (this.blockMgtcMonthList.length > 0) {
      for (var i = 0; i < this.blockMgtcMonthList.length; i++) {
        // let costObj: any;

        var costObj = this.blockMgtcMonthList[i];
        //   alert(costObj['ActualAb'])
      //  this.formZone.addControl("fromActualAb_" + i, new FormControl(costObj['ActualAb']));
        this.formZone.addControl("fromActualAb_" + i, new FormControl({ value: costObj.ActualAb, disabled: (this.freeze && !this.canFreeze) || this.isReadonly }));
        this.formZone.updateValueAndValidity();
        costObj['fromActualAb'] = "fromActualAb_" + i;

      }
    }
  }

  summary() {
    var result = 0;
    const self = this;
    this.blockMgtcMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }
    })
    return result;
  }

  summaryReduction() {
    const self = this;
    var result = 0;
    this.blockMgtcMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  summaryActual() {
    var result = 0;
    const self = this;
    this.blockMgtcMonthList.forEach(function (data) {
      if (self.formZone.get(data["fromActualAb"]).value != null) {
        result += parseFloat(self.formZone.get(data["fromActualAb"]).value);

      } else {
        result += 0;
      }
    });

    return result;
  }

  save() {

    const self = this;

    this.blockMgtcMonthList.forEach(function (data) {

      data["ActualAb"] = parseFloat(self.formZone.get(data["fromActualAb"]).value);

    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateBlockMgtcActual(this.blockMgtcMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('MGTC Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

  openUpdateCost(id) {

    this.campaignService.viewBlockMgtcMonth(id).subscribe(
      result => {
            const alertDialog = this._matDialog.open(BlockCostMgtcModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            resolverData: result.resolverData,
            freeze: this.freeze,
            canFreeze: this.canFreeze,
            isReadonly: this.isReadonly,
            abStage: this.abStage
          }
        });

        alertDialog.afterClosed()
          .subscribe(response => {
            if (response != 'Close') {
              this.childEvent.emit('Mgtc update');
            }
          });
      }
      , error => console.error(error));
  }

}
