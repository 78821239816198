import { formatNumber } from '@angular/common';

export class CommonUtilService {
  constructor($filter) {}

  public static dateHtml(data: any): String {
    if (data) {
      var dt = data;
      if (!(data instanceof Date)) {
        dt = new Date(data);
      }
      var yyyy = dt.getFullYear().toString();
      var mm = (dt.getMonth() + 1).toString(); // getMonth() is zero-based
      var dd = dt.getDate().toString();

      return (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + (parseInt(yyyy) + 543);
    } else {
      return '';
    }
  }

  public static formatDateLongMonth(data: any): String {
    if (!data)
      return null;

    let dt = new Date(data)
    var year = dt.getFullYear().toString();
    //const year = dt.getFullYear().toLocaleNumber();
    year = year.toString();
    const month = dt.toLocaleString('default', { month: 'long' });

    return ("0" + dt.getDate()).slice(-2) + " " + month + " " + year;
  }

  public static formatDate(data: any): String {
    if (!data)
    return null;

    let dt = new Date(data)
    var year = dt.getFullYear().toString();
    //const year = dt.getFullYear().toLocaleNumber();
    year = year.toString().substr(-2);
    return ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + year;
  }

  public static formatDateTime(data: any): String {
    if (!data)
      return null;
    var dt = new Date(data);
    var year = dt.getFullYear().toString();
    //year = (parseInt(year) - 543).toString();
    var hour = dt.getHours();
    var minute = dt.getMinutes();

    var hourFormatted = hour < 10 ? "0" + hour : hour;
    var minuteFormatted = minute < 10 ? "0" + minute : minute;

    return ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + year + " " + hourFormatted + ':' + minuteFormatted;
  }

  public static distinctByKey(data: any, key: string): any {
    var obj = {};
    
    for (var i = 0; i < data.length; i++) {
      obj[data[i][key]] = data[i][key];
      //console.log(obj[data[i][key]]);
    }      

    var newObjArray = new Array();
    for (var key in obj) {
      newObjArray.push(key);
    }

    return newObjArray;
  }
  public static formatCurrency(data: any): any {
    return formatNumber(Number(data), 'en-US', '1.2-2');
  }


  public static getPageAuthorized(pageCode: string, userDto: any): string{
    if (userDto) {
      var authorityTypeCode = userDto['userGroupAuthorityDtoList'].find(function (item) {
        return item.PageTypeCode == pageCode;
      });
      return authorityTypeCode.AuthorityTypeCode;
    } else {
      return 'V';
    }
  }

  public static getMonthShortNameByCode(monthCode) {
    try {
      var nameList = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'];
      return nameList[monthCode - 1];
    } catch (e) {
      return '';
    }

  }
}
