import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HC_exportData from 'highcharts/modules/export-data';
import HC_exporting from "highcharts/modules/exporting";

import { DashboardService } from '../_services/DashboardService.service';


more(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  dataList = [];
  cnt = 1;
  /*dataList = [{
    'id': 1, 'text': 'Actual 2020 YTD', 'C1': '8,195,000', 'C2': '3,598,000', 'C3': '4,470,000'
    , 'C4': '3,683,000', 'C5': '1,195,000', 'C6': '175,000', 'C7': ''
  },
  {
    'id': 2, 'text': 'AB 2020 YTD', 'C1': '13,295,000', 'C2': '11,498,000', 'C3': '6,170,000'
    , 'C4': '5,483,000', 'C5': '1,205,000', 'C6': '185,000', 'C7': ''
  },
  {
    'id': 3, 'text': 'AB 2020', 'C1': '22,152,000', 'C2': '21,216,000', 'C3': '14,601,000'
    , 'C4': '10,189,000', 'C5': '1,495,000', 'C6': '295,000', 'C7': ''
  },
  {
    'id': 4, 'text': 'Spending % - YTD', 'C1': '8,195,000', 'C2': '3,598,000', 'C3': '4,470,000'
    , 'C4': '3,683,000', 'C5': '1,195,000', 'C6': '195,000', 'C7': ''
  },
  {
    'id': 5, 'text': 'Spending % - AB', 'C1': '8,195,000', 'C2': '3,598,000', 'C3': '4,470,000'
    , 'C4': '3,683,000', 'C5': '1,195,000', 'C6': '195,000', 'C7': ''
  }];*/
  constructor(private dashboardService: DashboardService) { }

  currentYear: any = new Date().getFullYear();
  dataResult: any = {};
  ngOnInit(): void {

    this.dashboardService.searchBudgetSummaryAB({}).subscribe(
      result => {
        //console.log(result);
        this.dataResult = result;
        Highcharts.setOptions({
          chart: {
            style: {
              fontFamily: "Roboto, Helvetica Neue, sans-serif"
            }
          }
        });

        if (result && result.dashboardSummaryDto) {
        
          // initial summary chart
          this.initialChart1(result.dashboardSummaryDto.percentBudgetABYTD);
          
           
          
          this.initialChart2(result.dashboardSummaryDto.percentBudgetAB);
          this.initialChart5(result.dashboardSummaryDto);
        }

        if (result && result.dashboardBreakdownDtoList) {
          // initial break down chart
          this.dataList = result.dashboardBreakdownDtoList;
        //  console.table(this.dataList)
          this.initialChart3(result.dashboardBreakdownDtoList);
          this.initialChart4(result.dashboardBreakdownDtoList);
        }
        
      }
      , error => console.error(error));

   /* var dataListId1 = this.dataList
      .filter(value => value.id == 1)[0];
    var dataListId2 = this.dataList
      .filter(value => value.id == 2)[0];
    var dataListId3 = this.dataList
      .filter(value => value.id == 3)[0];
    var dataListId4 = this.dataList
      .filter(value => value.id == 4)[0];
    var dataListId5 = this.dataList
      .filter(value => value.id == 5)[0];

    var sum1 = 0;
    var sum2 = 0;
    var sum3 = 0;
    for (var i = 1; i <= 6; ++i) {
      sum1 += parseFloat(dataListId1["C" + i].replace(/,/g, ''));
      sum2 += parseFloat(dataListId2["C" + i].replace(/,/g, ''));
      sum3 += parseFloat(dataListId3["C" + i].replace(/,/g, ''));
    }
    dataListId1["C7"] = sum1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    dataListId2["C7"] = sum2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    dataListId3["C7"] = sum3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    for (var i = 1; i <= 7; ++i) {
      dataListId4["C" + i] = (parseFloat(dataListId1["C" + i].replace(/,/g, '')) * 100) / parseFloat(dataListId2["C" + i].replace(/,/g, ''));
      dataListId4["C" + i] = dataListId4["C" + i].toFixed(2) + '%';

      dataListId5["C" + i] = (parseFloat(dataListId1["C" + i].replace(/,/g, '')) * 100) / parseFloat(dataListId3["C" + i].replace(/,/g, ''));
      dataListId5["C" + i] = dataListId5["C" + i].toFixed(2) + '%';
    }*/

   /* setTimeout(() => {
      Highcharts.setOptions({
        chart: {
          style: {
            fontFamily: "Roboto, Helvetica Neue, sans-serif"
          }
        }
      });

      //this.initialChart1(null);
      //this.initialChart2(null);
      //this.initialChart3(null);
      //this.initialChart4(null);
      //this.initialChart5(null);
    }, 800);*/

  }

  initialChart1(data) {
    //var nav = this.navMenuComponent;
    //Highcharts.chart({});
    Highcharts.chart({


      chart: {
        renderTo: 'containerChart1',
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: data.toFixed(2) + '% of ' + this.currentYear+' AB (YTD)'
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      pane: {
        startAngle: -120,
        endAngle: 120,
        background: [{
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          //rotation: 'auto'
        },
        /*title: {
          text: 'km/h'
        },*/
        plotBands: [{
          from: 0,
          to: 60,
          //color: '#55BF3B' // green
          color: '#DF5353' // red
        }, {
          from: 60,
          to: 80,
          //color: '#DDDF0D' // yellow
          color: '#DF5353' // red
        }, {
          from: 80,
          to: 100,
          //color: '#DF5353' // red
          color: '#DF5353' // red
        }]
      },

      series: [{
        name: '% of ' + this.currentYear + ' AB (YTD)',
        type: undefined,
        data: [data],
        dataLabels: {
          format: '{point.y:.2f}'
        },
        tooltip: {
          //valueSuffix: ' %',
          pointFormat: '{point.y:.2f}%'
        }
      }]

    });

  }

  initialChart2(data) {
    //var nav = this.navMenuComponent;
    //Highcharts.chart({});
    Highcharts.chart({


      chart: {
        renderTo: 'containerChart2',
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: data.toFixed(2) + '% of ' + this.currentYear + ' AB'
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      pane: {
        startAngle: -120,
        endAngle: 120,
        background: [{
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          //rotation: 'auto'
        },
        /*title: {
          text: 'km/h'
        },*/
        plotBands: [{
          from: 0,
          to: 60,
          //color: '#55BF3B' // green
          color: '#DF5353' // red
        }, {
          from: 60,
          to: 80,
          //color: '#DDDF0D' // yellow
          color: '#DF5353' // red
        }, {
          from: 80,
          to: 100,
          //color: '#DF5353' // red
          color: '#DF5353' // red
        }]
      },

      series: [{
        name: '% of ' + this.currentYear + ' AB',
        type: undefined,
        data: [data],
        dataLabels: {
          format: '{point.y:.2f}'
        },
        tooltip: {
          //valueSuffix: ' %'
          pointFormat: '{point.y:.2f}%'
        }
      }]

    });

  }

  initialChart3(data) {
    Highcharts.chart({
      chart: {
        renderTo: 'containerChart3',
        type: 'column'
      },
      title: {
        text: 'Actual Cost Breakdown (YTD) – ' + this.currentYear
      },
      xAxis: {
        categories: ['Vessel', 'MGO', 'Helicopter', 'Supply Base', 'MGTC', 'Total']
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Millions (USD)',
          //skew3d: true
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      legend: {
        enabled: false
      },
      /*plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },*/


      tooltip: {
        //headerFormat: '<b>{point.key}</b><br>',
        //pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        pointFormat: '<span style="color:{series.color}">\u25CF</span> : {point.y:,.2f} '
      },
      colors: ['#376092', '#C0504D', '#43aa8b'],
      series: [{
        //name: 'John',
        type: undefined,
        data: [data[0].VESSEL / 1000000, data[0].MGO / 1000000, data[0].HELICOPTER / 1000000, data[0].SUPPLYBASE / 1000000, data[0].MGTC / 1000000, data[0].TOTAL / 1000000]
      }, {
        //name: 'Jane',
        type: undefined,
          data: [data[1].VESSEL / 1000000, data[1].MGO / 1000000, data[1].HELICOPTER / 1000000, data[1].SUPPLYBASE / 1000000, data[1].MGTC / 1000000, data[1].TOTAL / 1000000]
      }, {
        //name: 'Joe',
        type: undefined,
          data: [data[2].VESSEL / 1000000, data[2].MGO / 1000000, data[2].HELICOPTER / 1000000, data[2].SUPPLYBASE / 1000000, data[2].MGTC / 1000000, data[2].TOTAL / 1000000]
      }]
    });
  }

  initialChart4(data) {

    Highcharts.chart({
      chart: {
        renderTo: 'containerChart4',
        type: 'waterfall'
      },

      title: {
     //   text: 'Variances Analysis (Actual Cost Vs ' + this.currentYear+' YTD )'
        text: 'Year- To - Date Variance Analysis(AB Vs Actual Cost)'
      },

      xAxis: {
        type: 'category'
      },

      yAxis: {
        title: {
          text: 'MM USD'
        }
      },

      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },

      tooltip: {
        enabled: false
        //pointFormat: '<b>${point.y:,.2f}</b> MM USD'
      },

      series: [{
     //   upColor: '#376092',
       
        type: undefined,
        data: [
          {
            name: data[1].DESCRIPTION,
            color: '#376092',
            y: (data[1].TOTAL / 1000000)
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 2);
              }
            }
          }
          

          , {
            name: 'Vessel',
            color: data[1].VESSEL < data[0].VESSEL ? '#43aa8b' :'#C0504D',
            y: ((data[1].VESSEL / 1000000) - (data[0].VESSEL / 1000000)) * -1
            ,dataLabels: {
              enabled: true,
              formatter: function() {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
        }, {
            name: 'MGO',
            color: data[1].MGO < data[0].MGO ? '#43aa8b' : '#C0504D',
            y: ((data[1].MGO / 1000000) - (data[0].MGO / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
        }, 
        {
          name: 'Helicopter',
          color: data[1].HELICOPTER < data[0].HELICOPTER ? '#43aa8b' : '#C0504D',
          y: ((data[1].HELICOPTER / 1000000) - (data[0].HELICOPTER / 1000000)) * -1
          , dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y * -1, 2);
            }
          }
        }, {
          name: 'Supply Base',
          color: data[1].SUPPLYBASE < data[0].SUPPLYBASE ? '#43aa8b' : '#C0504D',
          y: ((data[1].SUPPLYBASE / 1000000) - (data[0].SUPPLYBASE / 1000000)) * -1
          , dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y * -1, 2);
            }
          }
        }, {
          name: 'MGTC',
          color: data[1].MGTC < data[0].MGTC ? '#43aa8b' : '#C0504D',
          y: ((data[1].MGTC / 1000000) - (data[0].MGTC / 1000000)) * -1
          , dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y * -1, 2);
            }
          }
          }, {

            name: data[0].DESCRIPTION,
            color: '#376092',
           // y: (data[0].TOTAL / 1000000)

            isSum:true
          , dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y, 2);
            }
          }
            //color: Highcharts.getOptions().colors[1]
          }
          ],
       
        pointPadding: 0
      }]
    });
  }

  initialChart5(data) {
    Highcharts.chart({
      chart: {
        renderTo: 'containerChart5',
        type: 'column'
      },
      title: {
        text: 'Budget Vs Actual Cost Summary  - ' + this.currentYear
      },
      xAxis: {
        categories: ['Actual ' + this.currentYear + ' YTD', 'AB ' + this.currentYear, 'Remaining Balance']
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Millions (USD)',
          //skew3d: true
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },

      tooltip: {
        //headerFormat: '<b>{point.key}</b><br>',
        //pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        pointFormat: '<span style="color:{series.color}">\u25CF</span> : {point.y:.2f} '
      },
      //colors: ['#376092', '#C0504D', '#43aa8b'],
      series: [{
        //name: 'John',
        type: undefined,
        data: [{ y: data.summaryActualYTD, color: '#376092' }, { y: data.summaryBudgetAB, color: '#C0504D' }, { y: data.remainingBalance, color: '#D8D803'}]
      }]
    });
  }



 

  tabChange($event) {
    console.log($event)
    this.cnt++;
    this.dataResult = this.dataResult ;
  }
}
