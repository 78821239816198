import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ABStageService {
  httpClient: any;

  constructor(private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl) {
  }


  updateABStage(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/ABStage/updateABStage', obj);
  }

  getABStage(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/ABStage/getABStage', obj);
  }
}
