import { Component, EventEmitter, Output, ViewEncapsulation, ViewChild, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']

})

export class NavMenuComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayName: any;
  roleName: any;

  user: any;
  roleList = [];
  userGroupAuthorityDtoList = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private http: HttpClient,
    private route: ActivatedRoute//,
 
  ) {
 
  }


  ngOnInit(): void {
    const user = this.authenticationService.userValue;
    if (user) {
      this.displayName = user.FirstName + ' ' + user.LastName;
      this.roleName = user.RoleName;
      this.userGroupAuthorityDtoList = user["userGroupAuthorityDtoList"];
      //console.log(this.userGroupAuthorityDtoList);
    }
  }

  @Output() public sidenavToggle = new EventEmitter();
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  isPermission(page) {
    var returnPermission = false;
    try {
      var authrorityDto;
      if (this.userGroupAuthorityDtoList && this.userGroupAuthorityDtoList.length > 0) {
        for (var i = 0; i < this.userGroupAuthorityDtoList.length; i++) {
          authrorityDto = this.userGroupAuthorityDtoList[i];
          //console.log(authrorityDto);
          if (authrorityDto && authrorityDto["PageTypeCode"] == page && "N" != authrorityDto["AuthorityTypeCode"]) {
            returnPermission = true;
          }
        }
      }
    } catch { }
    return returnPermission;
  }


  logout() {
    

    const user = this.authenticationService.userValue;
    
    if (user.PttepFlag) {
       // console.log(this.authService.instance.getActiveAccount())
      this.authenticationService.logout();
     //this.router.navigate(['login-ad']);
      this.authService.logout();
    } else {
        this.authenticationService.logout();
        this.router.navigate(['login-ad']);
    }
  
  
  }

}
