import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { thDatatables } from '../../_helpers/thDatatables';
import { UserService } from '../../_services/User.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare var pagingInputInit: any;

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.css']
})
export class UserGroupComponent implements OnInit {

  constructor(private userService: UserService,
    public _matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute)
  {
  }

  @Input() formZone: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dialogRef: any;
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {
    this.formZone = this.formBuilder.group({
      'UserGroupName': new FormControl(null),
      'ActiveFlag': new FormControl(null)
    });


    pagingInputInit();

    this.dtOptions = {
      pagingType: 'input',
      dom: 'B<"float-right"l>tip',
      language: thDatatables,
      processing: true,
      serverSide: true,
      responsive: true,
      scrollX: true,
      autoWidth: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      buttons: [
        {
          text: '+ Add',
          className: 'mat-focus-indicator btn-green mat-raised-button mat-button-base _mat-animation-noopable button-margin-bottom',
          action: () => {
           this.isAdd()
          },
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          }
        }
      ],
      "columns": [
        {
          data: null,
          className: 'dt-head-center dt-body-center',
          width: '50px',
          render: this.renderAction
        },
        {
          data: 'seqnum',
          className: 'dt-head-center dt-body-center',
          "width": "10px"
        },
        {
          data: 'User_Group_Name',
          className: 'dt-head-center dt-body-center',
          "width": "250px"
        },
      
        {
          data: 'Active_Flag',
          //sortProperty: 'countReservoir',
          className: 'dt-head-center dt-body-center',
          width: '70px',
          render: this.activeFlagData
        },
      
        {
          data: 'Update_Name',
          //sortProperty: 'ApproveName',
          className: 'dt-head-center dt-body-center',
          width: '125px'
        },
        {
          data: 'Update_Dttm',
          //sortProperty: 'ApproveDttm',
          className: 'dt-head-center dt-body-center',
          width: '125px',
          render: CommonUtilService.formatDateTime
        }
      ],
      columnDefs: [
        {
          "targets": [0,1],
          "orderable": false
        }
      ],
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        var criteria = {
          'start': dataTablesParameters.start,
          'length': dataTablesParameters.length,
          "sorterProperty": (this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty) ?
            this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty :
            this.dtOptions.columns[dataTablesParameters.order[0].column].data,
          "direction": dataTablesParameters.order[0].dir,
          "UserGroupName": this.formZone.get("UserGroupName").value,
          "ActiveFlag": (this.formZone.get("ActiveFlag").value == "Y") ? true : (this.formZone.get("ActiveFlag").value == "N") ? false : null
        }

   
        this.userService.searchUserGroupPaging(criteria).subscribe(res => {
          //console.log(res);
          callback({
            recordsTotal: res.iTotalRecords,
            iTotalDisplayRecords: res.iTotalDisplayRecords,
            recordsFiltered: res.recordsFiltered,
            data: res.detail
          });
        });
      },
      createdRow: (row: any, data: any | Object, index: number) => {
        const self = this;
        row.querySelectorAll('.mat-edit').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            //alert(data.IdEncoded);
            self.router.navigate(['/usergroupdetail', data.encID, "edit"]);
          });
        });
      }
    };
  }


  activeFlagData(data) {
    if (data == true) {
      return "Active";
    }
    return "Inactive";
  }

  renderAction(data, type, full, meta) {
    //console.log(data);
    return '<mat-icon role="img"  matripple="" class="gray-icon mat-icon notranslate mat-ripple material-icons mat-edit" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="Edit">edit</mat-icon>'
  }

  isSearch() {
    //this.isFirst = false;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  isClear() {
    this.formZone.get("UserGroupName").setValue(null);
    this.formZone.get("ActiveFlag").setValue(null);
    this.isSearch();
  }

  isAdd() {
    this.router.navigate(['/usergroupdetail', 0, "add"]);
  }
}
