import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { FooterComponent } from './footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { DataTablesModule } from 'angular-datatables';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

import { BlockUIHttpModule } from './shared/block-ui/block-ui-http.module';
import { BlockUIModule } from 'ng-block-ui';
//import { ErrorInterceptor } from './_helpers';
//import { ErrorInterceptor } from './error/ErrorInterceptor';
//import { AuthGuard } from './_helpers';
//import { MatRippleModule, MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
//import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule, MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

import { MomentUtcDateAdapter } from './_helpers/MomentUtcDateAdapter';
import { ConfirmModalComponent } from './common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from './common-modal/alert-modal/alert-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CampaignComponent } from './campaign/campaign.component';
import { UserGroupComponent } from './user/user-group/user-group.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { BudgetSummaryReportComponent } from './report/budget-summary-report/budget-summary-report.component';
import { OperationReportComponent } from './report/operation-report/operation-report.component';
import { BlockManagementComponent } from './master-data/block-management/block-management.component';
import { CostManagementComponent } from './master-data/cost-management/cost-management.component';
import { CostTypeManagementComponent } from './master-data/cost-type-management/cost-type-management.component';
import { ExtraBudgetManagementComponent } from './master-data/extra-budget-management/extra-budget-management.component';
import { HelicopterManagementComponent } from './master-data/helicopter-management/helicopter-management.component';
import { MarineSupportManagementComponent } from './master-data/marine-support-management/marine-support-management.component';
import { ProjectManagementComponent } from './master-data/project-management/project-management.component';
import { RigManagementComponent } from './master-data/rig-management/rig-management.component';
import { SupplyBaseManagementComponent } from './master-data/supply-base-management/supply-base-management.component';
import { VesselManagementComponent } from './master-data/vessel-management/vessel-management.component';

import { UserGroupModalComponent } from './user/user-group/user-group-modal/user-group-modal.component';

import { UserProfileModalComponent } from './user/user-profile/user-profile-modal/user-profile-modal.component';
import { CampaignModalComponent } from './campaign/campaign-modal/campaign-modal.component';
import { CampaignCostDetailComponent } from './campaign/campaign-cost-detail/campaign-cost-detail.component';
import { RigTypeManagementComponent } from './master-data/rig-type-management/rig-type-management.component';


import { ActivityManagementComponent } from './master-data/activity-management/activity-management.component';
import { SupportTypeManagementComponent } from './master-data/support-type-management/support-type-management.component';



import { DepartmentManagementComponent } from './master-data/department-management/department-management.component';
import { ParameterManagementComponent } from './master-data/parameter-management/parameter-management.component';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { SidenavListComponent } from './sidenav-list/sidenav-list.component';
import { ProjectManagementModalComponent } from './master-data/project-management/project-management-modal/project-management-modal.component';
import { UpdateLogComponent } from './update-log/update-log.component';
import { RigManagementModalComponent } from './master-data/rig-management/rig-management-modal/rig-management-modal.component';




import { BlockDataResolver, ExtraBudgetDataResolver, ExtraBudgetMasterResolver, HelicopterDataResolver, MarineSupportDataResolver, MarineSupportMasterResolver, RigDataResolver, RigMasterResolver, RigTypeDataResolver, SupplyBaseDataResolver, UserResolver, VesselDataResolver, VesselMasterResolver, ActivityDataResolver, SupportTypeDataResolver, ProjectMasterResolver, ProjectDataResolver, ParameterDataResolver, DepartmentDataResolver, DepartmentMasterResolver, VesselTypeDataResolver, CostTypeDataResolver } from './_helpers/SDBResolver';


import { CampaignRigComponent } from './campaign/campaign-rig/campaign-rig.component';
import { CampaignExtraBudgetComponent } from './campaign/campaign-extra-budget/campaign-extra-budget.component';
import { CampaignVesselComponent } from './campaign/campaign-vessel/campaign-vessel.component';
import { CampaignHelicopterComponent } from './campaign/campaign-helicopter/campaign-helicopter.component';
import { CampaignMarineSupportComponent } from './campaign/campaign-marine-support/campaign-marine-support.component';
import { CampaignSupplyBaseComponent } from './campaign/campaign-supply-base/campaign-supply-base.component';
import { BlockCostComponent } from './campaign/block-cost/block-cost.component';
import { BlockCostTakittaComponent } from './campaign/block-cost-takitta/block-cost-takitta.component';
import { BlockCostMgtcComponent } from './campaign/block-cost-mgtc/block-cost-mgtc.component';
import { BlockCostHelicopterComponent } from './campaign/block-cost-helicopter/block-cost-helicopter.component';
import { BlockCostExtraBudgetComponent } from './campaign/block-cost-extra-budget/block-cost-extra-budget.component';
import { VesselManagementModalComponent } from './master-data/vessel-management/vessel-management-modal/vessel-management-modal.component';
import { HelicopterManagementModalComponent } from './master-data/helicopter-management/helicopter-management-modal/helicopter-management-modal.component';
import { MarineSupportManagementModalComponent } from './master-data/marine-support-management/marine-support-management-modal/marine-support-management-modal.component';
import { CampaignRigModalComponent } from './campaign/campaign-rig/campaign-rig-modal/campaign-rig-modal.component';
import { CampaignHelicopterModalComponent } from './campaign/campaign-helicopter/campaign-helicopter-modal/campaign-helicopter-modal.component';
import { CampaignVesselModalComponent } from './campaign/campaign-vessel/campaign-vessel-modal/campaign-vessel-modal.component';
import { CampaignSupplyBaseModalComponent } from './campaign/campaign-supply-base/campaign-supply-base-modal/campaign-supply-base-modal.component';
import { CampaignMarineSupportModalComponent } from './campaign/campaign-marine-support/campaign-marine-support-modal/campaign-marine-support-modal.component';
import { CampaignExtraBudgetModalComponent } from './campaign/campaign-extra-budget/campaign-extra-budget-modal/campaign-extra-budget-modal.component';
import { BlockCostTakittaModalComponent } from './campaign/block-cost-takitta/block-cost-takitta-modal/block-cost-takitta-modal.component';
import { BlockCostMgtcModalComponent } from './campaign/block-cost-mgtc/block-cost-mgtc-modal/block-cost-mgtc-modal.component';
import { CurrencyInput } from './_helpers/currencyInput';
import { SupplyBaseManagementModalComponent } from './master-data/supply-base-management/supply-base-management-modal/supply-base-management-modal.component';
import { ExtraBudgetManagementModalComponent } from './master-data/extra-budget-management/extra-budget-management-modal/extra-budget-management-modal.component';
import { LoginComponent } from './authenticate/login/login.component';
import { LoginAdComponent } from './authenticate/login-ad/login-ad.component';
import { AuthGuard } from './_helpers/auth.guard';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { Currency4Input } from './_helpers/currency4Input';
import { BlockCostVesselComponent } from './campaign/block-cost-vessel/block-cost-vessel.component';
import { BlockCostRigComponent } from './campaign/block-cost-rig/block-cost-rig.component';
import { BlockCostPsbRsbComponent } from './campaign/block-cost-psb-rsb/block-cost-psb-rsb.component';
import { BlockCostMarineSupportComponent } from './campaign/block-cost-marine-support/block-cost-marine-support.component';
import { DashboardReductionComponent } from './home/dashboard-reduction/dashboard-reduction.component';

import { VesselTypeManagementComponent } from './master-data/vessel-type-management/vessel-type-management.component'; 
import { FlyingInfoManagementComponent } from './master-data/flying-info-management/flying-info-management.component';

 
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { RigTypeManagementModalComponent } from './master-data/rig-type-management/rig-type-management-modal/rig-type-management-modal.component';
import { BlockManagementModalComponent } from './master-data/block-management/block-management-modal/block-management-modal.component';

import { ParameterManagementModalComponent } from './master-data/parameter-management/parameter-management-modal/parameter-management-modal.component';
import { DepartmentManagementModalComponent } from './master-data/department-management/department-management-modal/department-management-modal.component';

import { ActivityManagementModalComponent } from './master-data/activity-management/activity-management-modal/activity-management-modal.component';
import { SupportTypeManagementModalComponent } from './master-data/support-type-management/support-type-management-modal/support-type-management-modal.component';





import { VesselTypeManagementModalComponent } from './master-data/vessel-type-management/vessel-type-management-modal/vessel-type-management-modal.component';
 
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      //clientId: 'af719df6-ff70-4c6c-bd7e-6a115eead575',
      //authority: "https://login.microsoftonline.com/c03b7ea5-365c-4a0f-b767-955babc64911",
      //redirectUri: 'https://sdbc-dev.pttep.com/login-ad'
      clientId: '126bd542-ccb3-4bcd-91eb-ce7e3f7782cd',
      authority: "https://login.microsoftonline.com/c03b7ea5-365c-4a0f-b767-955babc64911",
      redirectUri: 'https://sdbc.pttep.com/login-ad'
      
    }
  });
}
    //end azure ad
const defaultFormFieldOption: MatFormFieldDefaultOptions = {
  appearance: 'outline'
  , floatLabel: 'always'
  , hideRequiredMarker: true
};

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login-ad', component: LoginAdComponent },
  
  {
    path: '', component: MainLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: '', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'campaign', component: CampaignComponent },
      { path: 'user-group', component: UserGroupComponent },
      { path: 'user-profile', component: UserProfileComponent, resolve: { masterObj: UserResolver } },
      { path: 'user-group', component: UserGroupComponent },
      { path: 'budget-summary-report', component: BudgetSummaryReportComponent, resolve: { masterObj: SupplyBaseDataResolver } },
      { path: 'operation-report', component: OperationReportComponent, resolve: { masterObj: SupplyBaseDataResolver } },
      { path: 'block-management', component: BlockManagementComponent },
      { path: 'cost-management', component: CostManagementComponent },
      { path: 'cost-type-management', component: CostTypeManagementComponent },
      { path: 'extra-budget-management', component: ExtraBudgetManagementComponent, resolve: { masterObj: ExtraBudgetMasterResolver } },
      { path: 'helicopter-management', component: HelicopterManagementComponent },
      { path: 'marine-support-management', component: MarineSupportManagementComponent, resolve: { masterObj: MarineSupportMasterResolver } },
 
      { path: 'project-management', component: ProjectManagementComponent, resolve: { masterObj: ProjectMasterResolver } },
      { path: 'project-management-modal/:id/:mode', component: ProjectManagementModalComponent, resolve: { masterObj: ProjectDataResolver } },
      { path: 'rig-management', component: RigManagementComponent, resolve: { masterObj: RigMasterResolver } },
      { path: 'supply-base-management', component: SupplyBaseManagementComponent },
      { path: 'department-management', component: DepartmentManagementComponent, resolve: { masterObj: DepartmentMasterResolver }},
      { path: 'parameter-management', component: ParameterManagementComponent },
      { path: 'vessel-management', component: VesselManagementComponent, resolve: { masterObj: VesselMasterResolver } },
      {
        path: 'usergroupdetail/:id/:mode', component: UserGroupModalComponent
      },
      {
        path: 'rig-management-modal/:id/:mode', component: RigManagementModalComponent, resolve: { masterObj: RigDataResolver }
      },
      {
        path: 'vessel-management-modal/:id/:mode', component: VesselManagementModalComponent, resolve: { masterObj: VesselDataResolver }
      },
      {
        path: 'helicopter-management-modal/:id/:mode', component: HelicopterManagementModalComponent, resolve: { masterObj: HelicopterDataResolver }
      },
      {
        path: 'marine-support-management-modal/:id/:mode', component: MarineSupportManagementModalComponent, resolve: { masterObj: MarineSupportDataResolver }
      },
      {
        path: 'supply-base-management-modal/:id/:mode', component: SupplyBaseManagementModalComponent, resolve: { masterObj: SupplyBaseDataResolver }
      },
      {
        path: 'extra-budget-management-modal/:id/:mode', component: ExtraBudgetManagementModalComponent, resolve: { masterObj: ExtraBudgetDataResolver }
      },
 
      { path: 'user-profile-detail/:id/:mode', component: UserProfileModalComponent, resolve: { masterObj: UserResolver } },
      { path: 'rig-type-management', component: RigTypeManagementComponent },
      {
        path: 'rig-type-management-modal/:Code/:mode', component: RigTypeManagementModalComponent , resolve: { masterObj: RigTypeDataResolver }
      },
      {
        path: 'parameter-management-modal/:Code/:mode', component: ParameterManagementModalComponent, resolve: { masterObj: ParameterDataResolver }
      },
      {
        path: 'department-management-modal/:Code/:mode', component: DepartmentManagementModalComponent, resolve: { masterObj: DepartmentDataResolver }
      },
      {
        path: 'block-management-modal/:Code/:mode', component: BlockManagementModalComponent, resolve: { masterObj: BlockDataResolver }
      },

      { path: 'activity-management', component: ActivityManagementComponent },
      { path: 'support-type-management', component: SupportTypeManagementComponent },
      {
        path: 'activity-management-modal/:Code/:mode', component: ActivityManagementModalComponent, resolve: { masterObj: ActivityDataResolver }
      },
      {
        path: 'support-type-management-modal/:Code/:mode', component: SupportTypeManagementModalComponent, resolve: { masterObj: SupportTypeDataResolver }
      },

      { path: 'vessel-type-management', component: VesselTypeManagementComponent },

      { path: 'flying-info-management', component: FlyingInfoManagementComponent },

      {
        path: 'vessel-type-management-modal/:Code/:mode', component: VesselTypeManagementModalComponent,  resolve: { masterObj: VesselTypeDataResolver }
       
      },

      


    ]
 
  },
  
  { path: 'counter', component: CounterComponent },
  { path: 'fetch-data', component: FetchDataComponent },
 
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SidenavListComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    FooterComponent,
    CurrencyInput,
    Currency4Input,
    MainLayoutComponent,
    CampaignComponent,
    UserGroupComponent,
    UserProfileComponent,
    BudgetSummaryReportComponent,
    BlockManagementComponent,
    CostManagementComponent,
    
    ExtraBudgetManagementComponent,
    HelicopterManagementComponent,
    MarineSupportManagementComponent,
    ProjectManagementComponent,
    ProjectManagementModalComponent,
    RigManagementComponent,
    RigManagementModalComponent,
    SupplyBaseManagementComponent,
    VesselManagementComponent,
    UserGroupModalComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    UpdateLogComponent,
    UserProfileModalComponent,
    LoginComponent,
    LoginAdComponent,
    CampaignModalComponent,
    CampaignCostDetailComponent,
    CampaignRigComponent,
    CampaignExtraBudgetComponent,
    CampaignVesselComponent,
    CampaignHelicopterComponent,
    CampaignMarineSupportComponent,
    CampaignSupplyBaseComponent,
    BlockCostComponent,
    BlockCostTakittaComponent,
    BlockCostMgtcComponent,
    BlockCostHelicopterComponent,
    BlockCostExtraBudgetComponent, 
    VesselManagementModalComponent,
    MarineSupportManagementModalComponent,
    HelicopterManagementModalComponent,
    CampaignRigModalComponent,
    CampaignHelicopterModalComponent,
    CampaignVesselModalComponent,
    CampaignSupplyBaseModalComponent,
    CampaignMarineSupportModalComponent,
    CampaignExtraBudgetModalComponent,
    BlockCostTakittaModalComponent,
    BlockCostMgtcModalComponent,
    SupplyBaseManagementModalComponent,
    ExtraBudgetManagementModalComponent,
    BlockCostRigComponent,
    BlockCostVesselComponent,
    BlockCostPsbRsbComponent,
    BlockCostMarineSupportComponent,
 
    OperationReportComponent,
 
    DashboardReductionComponent,
 
    VesselTypeManagementComponent,
    VesselTypeManagementModalComponent,
    FlyingInfoManagementComponent,
    OperationReportComponent,
 
    RigTypeManagementComponent,
    RigTypeManagementModalComponent,
 
 
    BlockManagementModalComponent,
 
    ParameterManagementModalComponent,
    DepartmentManagementModalComponent,
    BlockManagementModalComponent,
 
    ActivityManagementComponent,
    ActivityManagementModalComponent,
    SupportTypeManagementComponent,
    SupportTypeManagementModalComponent,

 


    DepartmentManagementComponent,
    ParameterManagementComponent,
    CostTypeManagementComponent 
  ],

  entryComponents: [ProjectManagementModalComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    CampaignModalComponent,
    CampaignRigModalComponent,
    CampaignHelicopterModalComponent,
    CampaignVesselModalComponent,
    CampaignSupplyBaseModalComponent,
    CampaignMarineSupportModalComponent,
    CampaignExtraBudgetModalComponent,
    BlockCostTakittaModalComponent,
    BlockCostMgtcModalComponent
  ],

  imports: [
    //  BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    BlockUIHttpModule,
    BlockUIModule.forRoot(),
    BlockUIHttpModule.forRoot(),
    DataTablesModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    CommonModule,
    FlexLayoutModule,
    //    MatStepperModule,
    MatDividerModule,
    //    MatRippleModule,
    //    A2puploadModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatTabsModule,
    //  MatNativeDateModule,
    //  MatMomentDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MsalModule
    //  NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig)
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: defaultFormFieldOption
    },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD-MM-YYYY'
        },
        display: {
          //dateInput: 'MMM DD, YYYY'
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
 //   { provide: APP_BASE_HREF, useValue: '/PTTEPI-SDB/' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    //azure ad
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
    // end azure ad
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
