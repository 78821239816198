import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
 
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HC_exportData from 'highcharts/modules/export-data';
import HC_exporting from "highcharts/modules/exporting";
import { DashboardService } from '../../_services/DashboardService.service';

more(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);
@Component({
  selector: 'app-dashboard-reduction',
  templateUrl: './dashboard-reduction.component.html',
  styleUrls: ['./dashboard-reduction.component.css']
})
export class DashboardReductionComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() cnt;
  @Input() currentYear;
  @Input() dataResult: any = {};
  dataList = [];
  constructor(private dashboardService: DashboardService) { }
  
  ngOnInit(): void {
    


  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.cnt)
    if (this.dataResult && this.dataResult.dashboardSummaryDto) {
      
      Highcharts.setOptions({
        chart: {
          style: {
            fontFamily: "Roboto, Helvetica Neue, sans-serif"
          }
        }
      });
      // initial summary chart
      //console.log(this.dataResult.dashboardSummaryDto.percentBudgetReductionYTD)
      this.initialChart1(this.dataResult.dashboardSummaryDto.percentBudgetReductionYTD);
      this.initialChart2(this.dataResult.dashboardSummaryDto.percentBudgetReduction);
      this.initialChart5(this.dataResult.dashboardSummaryDto);
    }

    if (this.dataResult && this.dataResult.dashboardReductionBreakdownDtoList) {
      // initial break down chart
      this.dataList = this.dataResult.dashboardReductionBreakdownDtoList;
      //  console.table(this.dataList)
      this.initialChart3(this.dataResult.dashboardReductionBreakdownDtoList);
      this.initialChart4(this.dataResult.dashboardReductionBreakdownDtoList);
    }
 
 
  }

  initialChart1(data) {
    //var nav = this.navMenuComponent;
    //Highcharts.chart({});
    Highcharts.chart({


      chart: {
        renderTo: 'containerReductionChart1',
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: data.toFixed(2) + '% of ' + this.currentYear + ' Revised AB (YTD)'
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      pane: {
        startAngle: -120,
        endAngle: 120,
        background: [{
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          //rotation: 'auto'
        },
        /*title: {
          text: 'km/h'
        },*/
        plotBands: [{
          from: 0,
          to: 60,
          //color: '#55BF3B' // green
          color: '#DF5353' // red
        }, {
          from: 60,
          to: 80,
          //color: '#DDDF0D' // yellow
          color: '#DF5353' // red
        }, {
          from: 80,
          to: 100,
          //color: '#DF5353' // red
          color: '#DF5353' // red
        }]
      },

      series: [{
        name: '% of ' + this.currentYear + ' Revised AB (YTD)',
        type: undefined,
        data: [data],
        dataLabels: {
          format: '{point.y:.2f}'
        },
        tooltip: {
          //valueSuffix: ' %',
          pointFormat: '{point.y:.2f}%'
        }
      }]

    });

  }

  initialChart2(data) {
    //var nav = this.navMenuComponent;
    //Highcharts.chart({});
    Highcharts.chart({


      chart: {
        renderTo: 'containerReductionChart2',
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: data.toFixed(2) + '% of ' + this.currentYear + ' Revised AB'
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      pane: {
        startAngle: -120,
        endAngle: 120,
        background: [{
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          //rotation: 'auto'
        },
        /*title: {
          text: 'km/h'
        },*/
        plotBands: [{
          from: 0,
          to: 60,
          //color: '#55BF3B' // green
          color: '#DF5353' // red
        }, {
          from: 60,
          to: 80,
          //color: '#DDDF0D' // yellow
          color: '#DF5353' // red
        }, {
          from: 80,
          to: 100,
          //color: '#DF5353' // red
          color: '#DF5353' // red
        }]
      },

      series: [{
        name: '% of ' + this.currentYear + ' AB',
        type: undefined,
        data: [data],
        dataLabels: {
          format: '{point.y:.2f}'
        },
        tooltip: {
          //valueSuffix: ' %'
          pointFormat: '{point.y:.2f}%'
        }
      }]

    });

  }


  initialChart5(data) {
    Highcharts.chart({
      chart: {
        renderTo: 'containerReductionChart5',
        type: 'column'
      },
      title: {
        text: 'Revised AB  Vs Actual Cost Summary  - ' + this.currentYear
      },
      xAxis: {
        categories: ['Actual ' + this.currentYear + ' YTD', 'Revised AB ' + this.currentYear, 'Remaining Balance']
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Millions (USD)',
          //skew3d: true
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },

      tooltip: {
        //headerFormat: '<b>{point.key}</b><br>',
        //pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        pointFormat: '<span style="color:{series.color}">\u25CF</span> : {point.y:.2f} '
      },
      //colors: ['#376092', '#C0504D', '#43aa8b'],
      series: [{
        //name: 'John',
        type: undefined,
        data: [{ y: data.summaryActualYTD, color: '#376092' }, { y: data.summaryBudgetReduction, color: '#C0504D' }, { y: (data.summaryBudgetReduction - data.summaryActualYTD), color: '#D8D803' }]
      }]
    });
  }

  initialChart3(data) {
    Highcharts.chart({
      chart: {
        renderTo: 'containerReductionChart3',
        type: 'column'
      },
      title: {
        text: 'Actual Cost Breakdown (YTD) – ' + this.currentYear
      },
      xAxis: {
        categories: ['Vessel', 'MGO', 'Helicopter', 'Supply Base', 'MGTC', 'Total']
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Millions (USD)',
          //skew3d: true
        }
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      legend: {
        enabled: false
      },
      /*plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },*/


      tooltip: {
        //headerFormat: '<b>{point.key}</b><br>',
        //pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        pointFormat: '<span style="color:{series.color}">\u25CF</span> : {point.y:,.2f} '
      },
      colors: ['#376092', '#C0504D', '#43aa8b'],
      series: [{
        //name: 'John',
        type: undefined,
        data: [data[0].VESSEL / 1000000, data[0].MGO / 1000000, data[0].HELICOPTER / 1000000, data[0].SUPPLYBASE / 1000000, data[0].MGTC / 1000000, data[0].TOTAL / 1000000]
      }, {
        //name: 'Jane',
        type: undefined,
        data: [data[1].VESSEL / 1000000, data[1].MGO / 1000000, data[1].HELICOPTER / 1000000, data[1].SUPPLYBASE / 1000000, data[1].MGTC / 1000000, data[1].TOTAL / 1000000]
      }, {
        //name: 'Joe',
        type: undefined,
        data: [data[2].VESSEL / 1000000, data[2].MGO / 1000000, data[2].HELICOPTER / 1000000, data[2].SUPPLYBASE / 1000000, data[2].MGTC / 1000000, data[2].TOTAL / 1000000]
      }]
    });
  }

  initialChart4(data) {

    Highcharts.chart({
      chart: {
        renderTo: 'containerReductionChart4',
        type: 'waterfall'
      },

      title: {
        //   text: 'Variances Analysis (Actual Cost Vs ' + this.currentYear+' YTD )'
        text: 'Year- To - Date Variance Analysis(Revised AB Vs Actual Cost)'
      },

      xAxis: {
        type: 'category'
      },

      yAxis: {
        title: {
          text: 'MM USD'
        }
      },

      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },

      tooltip: {
        enabled: false
        //pointFormat: '<b>${point.y:,.2f}</b> MM USD'
      },

      series: [{
        //   upColor: '#376092',

        type: undefined,
        data: [
          {
            name: data[1].DESCRIPTION,
            color: '#376092',
            y: (data[1].TOTAL / 1000000)
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 2);
              }
            }
          }


          , {
            name: 'Vessel',
            color: data[1].VESSEL < data[0].VESSEL ? '#43aa8b' : '#C0504D',
            y: ((data[1].VESSEL / 1000000) - (data[0].VESSEL / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
          }, {
            name: 'MGO',
            color: data[1].MGO < data[0].MGO ? '#43aa8b' : '#C0504D',
            y: ((data[1].MGO / 1000000) - (data[0].MGO / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
          },
          {
            name: 'Helicopter',
            color: data[1].HELICOPTER < data[0].HELICOPTER ? '#43aa8b' : '#C0504D',
            y: ((data[1].HELICOPTER / 1000000) - (data[0].HELICOPTER / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
          }, {
            name: 'Supply Base',
            color: data[1].SUPPLYBASE < data[0].SUPPLYBASE ? '#43aa8b' : '#C0504D',
            y: ((data[1].SUPPLYBASE / 1000000) - (data[0].SUPPLYBASE / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
          }, {
            name: 'MGTC',
            color: data[1].MGTC < data[0].MGTC ? '#43aa8b' : '#C0504D',
            y: ((data[1].MGTC / 1000000) - (data[0].MGTC / 1000000)) * -1
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y * -1, 2);
              }
            }
          }, {

            name: data[0].DESCRIPTION,
            color: '#376092',
            // y: (data[0].TOTAL / 1000000)

            isSum: true
            , dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 2);
              }
            }
            //color: Highcharts.getOptions().colors[1]
          }
        ],

        pointPadding: 0
      }]
    });
  }
 

}
