export const thDatatables = {
  'emptyTable': '- Data not found -',
  'info': 'Show _START_ to _END_ from _TOTAL_ Record(s)',
  'infoEmpty': 'Show 0 to 0 from 0 Record(s)',
  //'infoFiltered': '(filtrert fra _MAX_ totalt antall linjer)',
  'infoPostFix': '',
  'infoThousands': ' ',
  'loadingRecords': 'Loading...',
  'lengthMenu': 'Show: _MENU_ Records/ Page',
  'processing': 'Loading...',
  //'search': 'S&oslash;k:',
  'url': '',
  //'zeroRecords': 'Ingen rader matcher s&oslash;ket',
  'paginate': {
    'first': 'First Page',
    'previous': 'Previous Page',
    'next': 'Next Page',
    'last': 'Last Page'
  }
  //,
  //'aria': {
  //  'sortAscending': ': aktiver for å sortere kolonnen stigende',
  //  'sortDescending': ': aktiver for å sortere kolonnen synkende'
  //}
};
