import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-rig-management-modal',
  templateUrl: './rig-management-modal.component.html',
  styleUrls: ['./rig-management-modal.component.css']
})

export class RigManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  dtOptions: any = {};
  costList = [];
  addCostFlag: any = true;
  isReadonly: boolean = false;
  paramId: any;
  pageMode: any;
  masterObj: any;
  rigTypeList: any;
  costTypeCodeList: any[];
  costTypeNameList: any;
  costDefaluteList: any[];
  costNameList: any[] = [];
  defaluteFlagList: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,  ) { }

  projectList: any[] = [];
  activityList: any[] = [];
  costDtoList: any[] = [];
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    this.rigTypeList = this.masterObj.tcRigType;
    this.projectList = this.masterObj.tcProject;
    this.activityList = this.masterObj.tcActivity;
    this.costDtoList = this.masterObj.costListDto;
    this.costTypeCodeList = this.masterObj.tcCostType;
    this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "MGO" && item.DefaluteFlag == true);

    this.formZone = this.formBuilder.group({
      'RigName': new FormControl({ value: this.masterObj?.resolverData?.RigName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'RigTypeCode': new FormControl({ value: this.masterObj?.resolverData?.RigTypeCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ActiveFlag': new FormControl({ value: this.masterObj?.resolverData?.ActiveFlag == true ? true : this.masterObj?.resolverData?.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ProjectCode': new FormControl({ value: this.masterObj?.resolverData?.ProjectCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ActivityCode': new FormControl({ value: this.masterObj?.resolverData?.ActivityCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'RigCostList': this.formBuilder.array([]),
      'CreateDate': new FormControl({ value: "", disabled: true }),
      'CreateName': new FormControl({ value: "", disabled: true }),
      'UpdateDate': new FormControl({ value: "", disabled: true }),
      'UpdateName': new FormControl({ value: "", disabled: true }),
    });

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.loadCostDefaluteFlag(this.costDefaluteList);
      this.formZone.get("ActiveFlag").setValue(true);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.RigCostList &&
      this.masterObj?.resolverData.RigCostList?.length > 0) {
      this.loadCost(this.masterObj?.resolverData.RigCostList);
    }

  }

  loadCost(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  loadCostDefaluteFlag(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  addCost() {
    this.costItems.push(this.formBuilder.group({
      "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
    }));
    this.checkAddCost();
  }


  deleteCost(index) {
    this.costItems.removeAt(index);
    this.costNameList.splice(index, 1);
    this.defaluteFlagList.splice(index, 1);
    this.addCostFlag = true;
  }

  checkAddCost() {
    if (this.costItems.length >= this.costDtoList.filter(x => x.VariableTypeCode == 'C' && x.CostTypeCode == "MGO").length) {
      this.addCostFlag = false;
    }
  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      item.get("CostId").setValue(null);
      this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false);
    }
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/rig-management']);
  }

  get costItems(): FormArray {
    return this.formZone.get('RigCostList') as FormArray;
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";
    console.log(requestObj);
    if (!this.formZone.valid || message) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }
      return;
    }

    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }
   
    this.MasterData.checkRigCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveRig(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/rig-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }
}
