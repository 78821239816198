import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
  
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { thDatatables } from '../../_helpers/thDatatables';
import { UserService } from '../../_services/User.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare var pagingInputInit: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(private userService: UserService,
    public _matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute)
  {
  }

  masterObj: any;
  @Input() formZone: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dialogRef: any;
  

  roleList: any;
  locationList: any;

  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
    }];

  pttepList: any[] = [{
    Code: true,
    Description: "PTT User"
  }, {
    Code: false,
    Description: "Other User"
  }];

  ngOnInit(): void {
    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    //this.roleList = this.masterObj.tmrUserGroups;
    this.locationList = this.masterObj.tcLocation;

    this.userService.searchUserGroupPaging({}).subscribe(
      data => {
        //console.log(data);
        this.roleList = data.detail
      }
    );

    //console.log(this.roleList);
    

    this.formZone = this.formBuilder.group({
      'UserName': new FormControl(null),
      'UserGroupId': new FormControl(null),
      'FirstName': new FormControl(null),
      'LastName': new FormControl(null),
      'LocationCode': new FormControl(null),
      'ActiveFlag': new FormControl(null),
      'PttepFlag': new FormControl(null)
    });


    pagingInputInit();

    this.dtOptions = {
      pagingType: 'input',
      dom: 'B<"float-right"l>tip',
      language: thDatatables,
      processing: true,
      serverSide: true,
      responsive: true,
      scrollX: true,
      autoWidth: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      buttons: [
        {
          text: '+ Add',
          className: 'mat-focus-indicator btn-green mat-raised-button mat-button-base _mat-animation-noopable button-margin-bottom',
          action: () => {
            this.isAdd()
          },
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          }
        }
      ],
      "columns": [
        {
          data: null,
          className: 'dt-head-center dt-body-center',
          width: '50px',
          render: this.renderAction
        },
        {
          data: 'seqnum',
          className: 'dt-head-center dt-body-center',
          "width": "10px"
        },
        {
          data: 'UserName',
          sortProperty: 'User_Name',
          className: 'dt-head-center dt-body-center',
          "width": "120px"
        },

        {
          //data: 'role',
          data: 'UserGroupDescription',
          sortProperty: 'USER_GROUP_ID',
          className: 'dt-head-center dt-body-center',
          width: '100px'
          
        },
        {
          data: 'FirstName',
          sortProperty: 'FIRST_NAME',
          className: 'dt-head-center dt-body-center',
          width: '100px'

        },
        {
          data: 'LastName',
          sortProperty: 'LAST_NAME',
          className: 'dt-head-center dt-body-center',
          width: '100px'

        },
        {
          data: 'PttepFlag',
          sortProperty: 'PTTEP_FLAG',
          className: 'dt-head-center dt-body-center',
          width: '100px',
          render : this.renderPttUserFlag
        },
        {
          data: 'ActiveFlag',
          sortProperty: 'ACTIVE_FLAG',
          className: 'dt-head-center dt-body-center',
          width: '100px',
          render: this.activeFlagData
        },

        {
          data: 'UpdateName',
          sortProperty: 'UPDATE_NAME',
          className: 'dt-head-center dt-body-center',
          width: '125px'
        },
        {
          data: 'UpdateDttm',
          sortProperty: 'UPDATE_DTTM',
          className: 'dt-head-center dt-body-center',
          width: '125px',
          render: CommonUtilService.formatDateTime
        }
      ],
      columnDefs: [
        {
          "targets": [0,1],
          "orderable": false
        }
      ],
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        var criteria = {
          'start': dataTablesParameters.start,
          'length': dataTablesParameters.length,
          "sorterProperty": (this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty) ?
            this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty :
            this.dtOptions.columns[dataTablesParameters.order[0].column].data,
          "direction": dataTablesParameters.order[0].dir,
          "UserName": this.formZone.get("UserName").value,
          "UserGroupId": this.formZone.get("UserGroupId").value,
          "FirstName": this.formZone.get("FirstName").value,
          "LastName": this.formZone.get("LastName").value,
          "LocationCode": this.formZone.get("LocationCode").value,
          "PttepFlag": this.formZone.get("PttepFlag").value,
          "ActiveFlag": (this.formZone.get("ActiveFlag").value == "Y") ? true : (this.formZone.get("ActiveFlag").value == "N") ? false : null,
        }

        this.userService.searchUserPaging(criteria).subscribe(res => {
          //console.log(res);
          callback({
            recordsTotal: res.iTotalRecords,
            iTotalDisplayRecords: res.iTotalDisplayRecords,
            recordsFiltered: res.recordsFiltered,
            data: res.detail
          });
        });


        /*callback({
          recordsTotal: 5,
          iTotalDisplayRecords: 5,
          recordsFiltered: 5,
          data: this.dataList
        });*/
      /*  this.userService.searchUserGroupPaging(criteria).subscribe(res => {
          //console.log(res);
          callback({
            recordsTotal: res.iTotalRecords,
            iTotalDisplayRecords: res.iTotalDisplayRecords,
            recordsFiltered: res.recordsFiltered,
            data: res.detail
          });
        });*/
      },
      createdRow: (row: any, data: any | Object, index: number) => {
        const self = this;
        row.querySelectorAll('.mat-edit').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            //alert(data.IdEncoded);
            self.router.navigate(['/user-profile-detail', data.encID, "edit"]);
          });
        });
      }
    };
  }


  activeFlagData(data) {
    if (data == true) {
      return "Active";
    }
    return "Inactive";
  }

  renderPttUserFlag(data) {
    if (data == true) {
      return "Yes";
    }
    return "No";
  }

  renderAction(data, type, full, meta) {
    //console.log(data);
    return '<mat-icon role="img"  matripple="" class="gray-icon mat-icon notranslate mat-ripple material-icons mat-edit" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="Edit">edit</mat-icon>'
  }

  isSearch() {
    //this.isFirst = false;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  isClear() {
    this.formZone.get("UserName").setValue(null);
    this.formZone.get("UserGroupId").setValue(null);
    this.formZone.get("FirstName").setValue(null);
    this.formZone.get("LastName").setValue(null);
    this.formZone.get("LocationCode").setValue(null);
    this.formZone.get("ActiveFlag").setValue(null);
    this.formZone.get("PttepFlag").setValue(null);
    this.isSearch();
  }

  isAdd() {

    this.router.navigate(['/user-profile-detail', 0, "add"]);

  }
}
