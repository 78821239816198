import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { CampaignRigModalComponent } from './campaign-rig-modal/campaign-rig-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CampaignService } from '../../_services/Campaign.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-campaign-rig',
  templateUrl: './campaign-rig.component.html',
  styleUrls: ['./campaign-rig.component.css']
})
export class CampaignRigComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() campaignRigMonthList: any[];
  @Input() CampaignName: string;
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {

  
  }
  summaryReductionMgoCost() {
    const self = this;

    var result = 0;
    this.campaignRigMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageMgoAb"];
      } else {
        result += data["ReductionPercentageMgoCf"];
      }

    })
    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
 

  //  console.log(this.campaignRigMonthList)
   // alert('rig c '+this.CampaignName)
    this.formZone = new FormGroup({});
    this.loadCost();
  }

  summary() {
    var result = 0;
    const self = this;
    this.campaignRigMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["MgoCostAb"];
      } else {
        result += data["MgoCostCf"];
      }
     
    })
    return result;
  }


  summaryActual() {
    var result = 0;
    const self = this;
    this.campaignRigMonthList.forEach(function (data) {

      result+= parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });

    return result;
  }



  loadCost() {
    if (this.campaignRigMonthList.length>0) { 
    for (var i = 0; i < this.campaignRigMonthList.length; i++) {
     // let costObj: any;
   
      var costObj = this.campaignRigMonthList[i];
   //   alert(costObj['ActualMgoCostAb'])
     // this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl(costObj['ActualMgoCostAb']));
      this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl({ value: costObj.ActualMgoCostAb, disabled: this.freeze }));

      this.formZone.updateValueAndValidity();
      costObj['fromActualMgoCostAb'] = "fromActualMgoCostAb_" + i;
    
    }
    }
  }

  save() {

    const self = this;
   
    this.campaignRigMonthList.forEach(function (data) {
    
      data["ActualMgoCostAb"] = parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateRigActual(this.campaignRigMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('rig Actual update');
              //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }




  openUpdateCost(id) {
  
    this.campaignService.viewRigMonth(id).subscribe(
      result => {

       //  console.log(result)

        const alertDialog = this._matDialog.open(CampaignRigModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            CampaignName: this.CampaignName,
            resolverData: result.resolverData,
            freeze: this.freeze,
            abStage: this.abStage,
            pageType: 'add',
            action: 'edit',
            canFreeze: this.canFreeze,
            isReadonly: this.isReadonly
          }
        });

        alertDialog.afterClosed()
          .subscribe(response => {
  
            if (response != 'Close') {
              //   self.isSearch();
              //   self.cdr.detectChanges();
         
              this.childEvent.emit('rig update');
            }
          });

      }
      , error => console.error(error));
  


  
  }


}
