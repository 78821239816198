import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { MasterDataService } from "../_services/MasterData.service";

@Injectable({ providedIn: 'root' })
export class RigMasterResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getRigMasterObject();
  }
}

@Injectable({ providedIn: 'root' })
export class RigDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getRigDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class VesselMasterResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getVesselMasterObject();
  }
}

@Injectable({ providedIn: 'root' })
export class VesselDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getVesselDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getUserMasterObject();
  }
}



@Injectable({ providedIn: 'root' })
export class HelicopterDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getHelicopterDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class MarineSupportMasterResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getMarineSupportrMasterObject();
  }
}

@Injectable({ providedIn: 'root' })
export class MarineSupportDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getMarineSupportDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class SupplyBaseDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getSupplyBaseDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class ExtraBudgetMasterResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getExtraBudgetMasterObject();
  }
}

@Injectable({ providedIn: 'root' })
export class ExtraBudgetDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getExtraBudgetDataObject(route.params["id"]);
  }
}

@Injectable({ providedIn: 'root' })
export class RigTypeDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }
  
  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getRigTypeDataObject(route.params["Code"]);
  }
}

//
@Injectable({ providedIn: 'root' })
export class ParameterDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getParameterDataObject(route.params["Code"]);
  }
}

@Injectable({ providedIn: 'root' })
export class DepartmentDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getDepartmentDataObject(route.params["Code"]);
  }
}

@Injectable({ providedIn: 'root' })
export class DepartmentMasterResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getDepartmentMasterObject();
  }
}

@Injectable({ providedIn: 'root' })
export class ActivityDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getActivityDataObject(route.params["Code"]);
  }
}

@Injectable({ providedIn: 'root' })
export class BlockDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getBlockDataObject(route.params["Code"]);
  }
}

@Injectable({ providedIn: 'root' })
export class SupportTypeDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getSupportTypeDataObject(route.params["Code"]);
  }
}


@Injectable({ providedIn: 'root' })
export class VesselTypeDataResolver implements Resolve<any[]> {
  public masterDatas: any;

  constructor(private _masterDataService: MasterDataService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getVesselTypeDataObject(route.params["Code"]);
  }
}


@Injectable({ providedIn: 'root' })
export class CostTypeDataResolver implements Resolve<any[]> {
  public masterDatas: any;
  constructor(private _masterDataService: MasterDataService) { }
  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getCostTypeDataObject(route.params["Code"]);
  }
}
//
@Injectable({ providedIn: 'root' })
export class ProjectMasterResolver implements Resolve<any[]> {
  public masterDatas: any;
  constructor(private _masterDataService: MasterDataService) { }
  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getProjectMasterObject();
  }
}


//
@Injectable({ providedIn: 'root' })
export class ProjectDataResolver implements Resolve<any[]> {
  public masterDatas: any;
  constructor(private _masterDataService: MasterDataService) { }
  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._masterDataService.getProjectDataObject(route.params["id"]);
  }
}

