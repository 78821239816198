import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { thDatatables } from '../../_helpers/thDatatables';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { MasterDataService } from '../../_services/MasterData.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
declare var pagingInputInit: any;

@Component({
  selector: 'app-department-management',
  templateUrl: './department-management.component.html',
  styleUrls: ['./department-management.component.css']
})
export class DepartmentManagementComponent implements OnInit {

  constructor(private MasterData: MasterDataService,
    private authenticationService: AuthenticationService,
    public _matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) { }
  masterObj: any;
  isReadonly: boolean = false;
  @Input() formZone: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any;
  dialogRef: any;
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];
  SupportTypeList: any[] = [];

  ngOnInit(): void {
    var page = '';
    /*const user = this.authenticationService.userValue;
    if (user) {
      var authorizedCode = CommonUtilService.getPageAuthorized(page, user);
      this.isReadonly = authorizedCode == 'V';
    }*/
    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    this.SupportTypeList = this.masterObj.tcSupportType;

    this.formZone = this.formBuilder.group({
      'DepartmentName': new FormControl(null),
      'SupportTypeCode': new FormControl(null),
      'ActiveFlag': new FormControl(null)
    });

    pagingInputInit();
    this.dtOptions = {
      pagingType: 'input',
      dom: 'B<"float-right"l>tip',
      language: thDatatables,
      processing: true,
      serverSide: true,
      responsive: true,
      scrollX: true,
      autoWidth: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      //Configure the buttons
      buttons: [
        {
          text: '+ New',
          className: 'mat-focus-indicator btn-green mat-raised-button mat-button-base _mat-animation-noopable button-margin-bottom',
          action: () => {
            this.isAdd()
          },
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          }
        }
      ],
      "columns": [
        {
          data: null,
          className: 'dt-head-center dt-body-center',
          width: '10px',
          render: this.renderAction
        },
        {
          data: 'seqnum',
          className: 'dt-head-center dt-body-center',
          "width": "10px"
        },
        {
          data: 'Code',
          className: 'dt-head-center dt-body-center',
          "width": "50px"
        },
        {
          data: 'Description',
          className: 'dt-head-center dt-body-center',
          "width": "125px"
        },
        {
          data: 'Support_Type_Code',
          className: 'dt-head-center dt-body-center',
          "width": "50px"
        },
        {
          data: 'Active_Flag',
          //sortProperty: 'countReservoir',
          className: 'dt-head-center dt-body-center',
          width: '50px',
          render: this.activeFlagData
        },
        {
          data: 'Update_Dttm',
          //sortProperty: 'ApproveName',
          className: 'dt-head-center dt-body-center',
          width: '100px',
          render: CommonUtilService.formatDateTime
        },
        {
          data: 'Update_Name',
          //sortProperty: 'ApproveDttm',
          className: 'dt-head-center dt-body-center',
          width: '100px'
        }
      ],
      columnDefs: [
        {
          "targets": [0, 1],
          "orderable": false
        }
      ],
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        var criteria = {
          'start': dataTablesParameters.start,
          'length': dataTablesParameters.length,
          "sorterProperty": (this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty) ?
            this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty :
            this.dtOptions.columns[dataTablesParameters.order[0].column].data,
          "direction": dataTablesParameters.order[0].dir,
          "DepartmentName": this.formZone.get("DepartmentName").value,
          "SupportTypeCode": this.formZone.get("SupportTypeCode").value,
          "ActiveFlag": (this.formZone.get("ActiveFlag").value == "Y") ? true : (this.formZone.get("ActiveFlag").value == "N") ? false : null,

        }

        this.MasterData.searchDepartmentPaging(criteria).subscribe(res => {
          setTimeout(() => {
            if (this.isReadonly) {
              this.dtOptions.buttons = [];
              $("div.dt-buttons").hide();
            }
            for (var i = 0; i < res.detail.length; ++i) {
              res.detail[i]['isReadonly'] = this.isReadonly;
            }
            callback({
              recordsTotal: res.iTotalRecords,
              iTotalDisplayRecords: res.iTotalDisplayRecords,
              recordsFiltered: res.recordsFiltered,
              data: res.detail
            }, 300);
          });
        });
      },
      createdRow: (row: any, data: any | Object, index: number) => {
        const self = this;

        row.querySelectorAll('.mat-edit').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            self.router.navigate(['/department-management-modal', data.CodeEncoded, "edit"]);
          });
        });

        row.querySelectorAll('.mat-view').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            self.router.navigate(['/department-management-modal', data.CodeEncoded, "view"]);
          });
        });
      }
    }
  }


  isAdd() {
    this.router.navigate(['/department-management-modal', 0, "add"]);
  }

  renderAction(data, type, full, meta) {
    console.log("renderAction");
    if (!data.isReadonly) {
      return '<mat-icon role="img"  matripple="" class="gray-icon mat-icon notranslate mat-ripple material-icons mat-edit" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="Edit">edit</mat-icon>'
    } else {
      return '<mat-icon-button _ngcontent-obq-c298="" role="img" color="primary" matripple="" class="mat-icon notranslate mat-ripple gray-icon material-icons mat-view" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="View">search</mat-icon-button>'
    }
  }

  activeFlagData(data) {
    if (data == true) {
      return "Active";
    }
    return "Inactive";
  }

  isSearch() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  isClear() {
    this.formZone.get("DepartmentName").setValue(null);
    this.formZone.get("ActiveFlag").setValue(null);
    this.formZone.get("SupportTypeCode").setValue(null);
    this.isSearch();
  }

}
