import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();

    /*this.http.post<any>('/account/getLogon', {})
      .pipe(map(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
      }));*/
  }

  public get userValue(): any {
    return this.userSubject.value;
  }

  isUserLoggedIn(): boolean {
    const user = this.userValue;
    return (user && user.token != null && user.token != "" && typeof user.token != "undefined");
  }

  login(username: string, password: string) {

    const model = {
      "username": username,
      "password": password
    }

    return this.http.post<any>("api/Account/Login", model)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

        if (user) {
          if (user["data"] && user["data"]["name"] == "Error") {
            //error from aws

          }
          else {
            var userEdited = JSON.stringify(user);
            /*userEdited["authorizeTypeCode"] = (userEdited["RoleCode"]) ?
              (userEdited["UserTypeCode"] + "_" + userEdited["RoleCode"]) : userEdited["UserTypeCode"];*/
            //localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('user', userEdited);
            this.userSubject.next(user);
          }
        }
        
        return user;
      }));
  }


  LoginPTTUser(username: string) {

    const model = {
      "Username": username
    }

   // console.log(model)
    return this.http.post<any>("api/Account/LoginPTTUser", model)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

        if (user) {
          if (user["data"] && user["data"]["name"] == "Error") {
            //error from aws

          }
          else {
            var userEdited = JSON.stringify(user);
            /*userEdited["authorizeTypeCode"] = (userEdited["RoleCode"]) ?
              (userEdited["UserTypeCode"] + "_" + userEdited["RoleCode"]) : userEdited["UserTypeCode"];*/
            //localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('user', userEdited);
            this.userSubject.next(user);
          }
        }

        return user;
      }));
  }

  

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.userSubject.next(null);
  }
  
  getLogon() {
    return this.http.post<any>("/Account/getLogon", {})
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  switchUser(logonName) {
    if (this.isUserLoggedIn()) {
      const model = {
        "username": logonName
      }
      return this.http.post<any>("api/Account/swithUser", model).pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user) {
          if (user["data"] && user["data"]["name"] == "Error") {
            //error from aws

          }
          else {
            localStorage.removeItem('user');
            this.userSubject.next(null);

            var userEdited = JSON.stringify(user);
            /*userEdited["authorizeTypeCode"] = (userEdited["RoleCode"]) ?
              (userEdited["UserTypeCode"] + "_" + userEdited["RoleCode"]) : userEdited["UserTypeCode"];*/
            //localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('user', userEdited);
            this.userSubject.next(user);
          }
        }

        return user;
      }));
    }
  }

  setCurrentRole(role: string) {
    /*return this.http.post<any>("/account/setCurrentRole", role)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        //this.userSubject.next(user);
        //return user;
        this.user['currentRole'] = role;
      }));*/
    //this.user['currentRole'] = role;
    //this.userSubject.value['currentRole'] = role;
    var user = this.userSubject.value;
    user['currentRole'] = role;
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
    return this.http.post<any>('/login/setCurrentRole', { 'role': role });
  }
}
