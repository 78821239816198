import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CampaignService } from '../../../_services/Campaign.service';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { CalculateCostService } from '../../../_helpers/CalculateCostService';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
@Component({
  selector: 'app-campaign-supply-base-modal',
  templateUrl: './campaign-supply-base-modal.component.html',
  styleUrls: ['./campaign-supply-base-modal.component.css']
})
export class CampaignSupplyBaseModalComponent implements OnInit {

  @Input() formZone: FormGroup;
  camopaignSupplyBaseMonth: any;
  countingList = 0;
  freeze: boolean;
  abStage: boolean;
  canFreeze: boolean;
  isReadonly: boolean;

  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    //   private userService: UserService,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.camopaignSupplyBaseMonth = this._data.resolverData;
    this.freeze = this._data.freeze;
    this.abStage = this._data.abStage;
    this.canFreeze = this._data.canFreeze;
    this.isReadonly = this._data.isReadonly;

    this.formZone = new FormGroup({
      rigName: new FormControl({ value: this.camopaignSupplyBaseMonth.SupplyBaseName, disabled: true }),
      campaignName: new FormControl({ value: this._data.CampaignName, disabled: true }),
      month: new FormControl({ value: CommonUtilService.getMonthShortNameByCode(this.camopaignSupplyBaseMonth.MonthCode), disabled: true }),
   
      year: new FormControl({ value: this.camopaignSupplyBaseMonth.Year, disabled: true }),
      VesselTrip: new FormControl({ value: this.abStage ? this.camopaignSupplyBaseMonth.VesselTripAb : this.camopaignSupplyBaseMonth.VesselTripCf, disabled: (this.freeze && !this.canFreeze) || this.isReadonly }),
    });

    this.loadCost();
  }

  formatDateTime(UpdateDttm) {
    return CommonUtilService.formatDateTime(UpdateDttm)
  }


  calculateCost() {
    const self = this;
    this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);

    });

    const calObj = new CalculateCostService();
    calObj.noVesselTripOffshore = parseFloat(self.formZone.get("VesselTrip").value);
    calObj.calculateCostList(this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList, this.camopaignSupplyBaseMonth.costParentDtoList);
    this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList.forEach(function (data) {

      self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
    });
  }


  loadCost() {

    for (var i = 0; i < this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList.length; i++) {
      var costObj = this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList[i];
      this.formZone.addControl("fromValueCf_" + i, new FormControl({ value: this.abStage ? costObj.ValueAb : costObj.ValueCf, disabled: (costObj.VariableTypeCode == 'D' || costObj.AllocateFlag || (this.freeze && !this.canFreeze) || this.isReadonly) }, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();
      costObj["fromValueCf"] = "fromValueCf_" + i;

      this.countingList++;

    }
  }


  save() {

    const self = this;
    this.camopaignSupplyBaseMonth.VesselTripCf = self.formZone.get("VesselTrip").value;

    this.camopaignSupplyBaseMonth.campaignSupplyBaseMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);
      if (self.abStage) {
        data["ValueAb"] = data["ValueCf"];
      }
    });
    this.camopaignSupplyBaseMonth.AbFlag = this.abStage;
    

    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateCampaignSupplyBaseMonth(this.camopaignSupplyBaseMonth).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

}
